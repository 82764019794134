import { createEntityAdapter, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { FETCH_STATUS_TYPES_ENUM } from 'src/@types/enums';
import { RootState } from '../store';
import {
  collection,
  query,
  where,
  limit,
  startAt as firestoreStartAt,
  getDocs,
  QueryConstraint,
  updateDoc,
  doc,
  orderBy,
  getDoc,
  setDoc,
  deleteDoc,
  Timestamp,
} from 'firebase/firestore';
import { DB } from 'src/contexts/FirebaseContext';
import { Form, Form_Typesense } from 'src/@types/firebase';
import { saveForm } from './formBuilder';
import { markFormSubmissionAsSeen } from './formSubmissions';
import { SearchParams } from 'typesense/lib/Typesense/Documents';
import useTypesense from 'src/hooks/useTypesense';

const FETCH_LIMIT = 30;

const handleSortBy = (sortBy: string | null) => {
  /*
  const SORT_BY_OPTIONS = [
  { value: 'titleAsc', label: 'Name: A-Z' },
  { value: 'titleDesc', label: 'Name: Z-A' },
  { value: 'dateCreatedAsc', label: 'Date Created: Oldest' },
  { value: 'dateCreatedDesc', label: 'Date Created: Newest' },
  { value: 'lastUpdatedAsc', label: 'Last Updated: Oldest' },
  { value: 'lastUpdatedDesc', label: 'Last Updated: Newest' },
];
  */

  let firebaseOrderBy = orderBy('dateCreated', 'desc');

  if (!sortBy) {
    return firebaseOrderBy;
  }

  switch (sortBy) {
    case 'titleAsc':
      firebaseOrderBy = orderBy('title', 'asc');
      break;
    case 'titleDesc':
      firebaseOrderBy = orderBy('title', 'desc');
      break;
    case 'dateCreatedAsc':
      firebaseOrderBy = orderBy('dateCreated', 'asc');
      break;
    case 'dateCreatedDesc':
      firebaseOrderBy = orderBy('dateCreated', 'desc');
      break;
    case 'lastUpdatedAsc':
      firebaseOrderBy = orderBy('lastUpdated', 'asc');
      break;
    case 'lastUpdatedDesc':
      firebaseOrderBy = orderBy('lastUpdated', 'desc');
      break;
    default:
      break;
  }

  return firebaseOrderBy;
};

// Fetch forms for the current user
export const fetchForms = createAsyncThunk<{
  forms: Form[];
  lastVisibleDoc: any;
  page: number;
}>('forms/fetchForms', async (_, { getState }) => {
  const state = getState() as RootState;
  const userId = state.user.id;
  const { filters, sortBy } = state.forms;
  let { lastVisibleDoc, page } = state.forms;

  const forms: Form[] = [];

  if (!filters.searchPhrase) {
    let firebaseOrderBy = handleSortBy(sortBy);

    const queryConstraints: QueryConstraint[] = [
      firebaseOrderBy,
      where('creatorId', '==', userId),
      limit(FETCH_LIMIT),
    ];

    if (lastVisibleDoc) {
      queryConstraints.push(firestoreStartAt(lastVisibleDoc));
    }

    const q = query(collection(DB, 'forms'), ...queryConstraints);

    const querySnapshot = await getDocs(q);
    lastVisibleDoc = querySnapshot.docs[querySnapshot.docs.length - 1];
    querySnapshot.forEach((doc) => {
      const item = { ...doc.data(), id: doc.id } as Form;
      forms.push(item);
    });
  } else {
    // increment page
    page += 1;

    const searchParameters: SearchParams = {
      q: filters.searchPhrase ? filters.searchPhrase : '*',
      query_by: 'title, description',
      query_by_weights: '4, 2',
      page: page,
      per_page: FETCH_LIMIT,
    };

    let filterBy = null;
    // Creator filters
    if (userId) {
      // const creatorFilter = `creatorIds:[${creatorIds.join()}]`;
      const creatorFilter = `creatorId:${userId}`;
      filterBy = filterBy ? filterBy + ' && ' + creatorFilter : creatorFilter;
    }

    if (filterBy) {
      searchParameters.filter_by = filterBy;
    }

    // Typesense search
    const client = useTypesense();

    const response = await client
      .collections<Form_Typesense>('forms')
      .documents()
      .search(searchParameters);

    if (response?.hits && response.hits.length !== 0) {
      forms.push(
        ...response.hits.map((hit) => {
          const item = hit.document;

          // Format the unix timestamps to Firestore Timestamps
          // e.g. dateCreatedUnix: 1737229052,
          // lastUpdatedUnix: 1737229052
          const dateCreated = item?.dateCreatedUnix
            ? Timestamp.fromMillis(item.dateCreatedUnix * 1000)
            : Timestamp.now();
          const lastUpdated = item?.lastUpdatedUnix
            ? Timestamp.fromMillis(item.lastUpdatedUnix * 1000)
            : Timestamp.now();

          // Remove Typesense only fields re: ExerciseTypesense
          delete item.dateCreatedUnix;
          delete item.lastUpdatedUnix;

          const form: Form = {
            ...item,
            id: hit.document.id,
            dateCreated,
            lastUpdated,
          };

          return form;
        })
      );
    }
  }

  return { forms, lastVisibleDoc, page };
});

// Delete a form
export const deleteForm = createAsyncThunk<string, { formId: string }>(
  'forms/deleteForm',
  async ({ formId }) => {
    const formRef = doc(DB, 'forms', formId);
    await deleteDoc(formRef);
    return formId;
  }
);

// Duplicate a form
export const duplicateForm = createAsyncThunk<Form, { formId: string }>(
  'forms/duplicateForm',
  async ({ formId }, { getState }) => {
    const state = getState() as RootState;

    // Get the form from the store
    let form = state.forms.entities[formId];

    // If the form is not in the store, fetch it from the database
    if (!form) {
      const formRef = doc(DB, 'forms', formId);
      const formDoc = await getDoc(formRef);
      form = { ...formDoc.data(), id: formDoc.id } as Form;
    }

    // Duplicate the form, delete the id and reset the newResponses count
    const newForm = {
      ...form,
      newResponses: 0,
      clients: [],
      dateCreated: Timestamp.now(),
      lastUpdated: Timestamp.now(),
      clientIds: [],
    };
    delete newForm.id;

    // Save the new form
    const newFormRef = doc(collection(DB, 'forms'));
    newForm.id = newFormRef.id;

    await setDoc(newFormRef, newForm);

    return newForm;
  }
);

export const updateFormClients = createAsyncThunk<
  {
    id: string;
    clients: Form['clients'];
    clientIds: Form['clientIds'];
  },
  { formId: string; clients: Form['clients'] }
>('forms/updateFormClients', async ({ formId, clients }) => {
  const formRef = doc(DB, 'forms', formId);
  const clientIds = clients.map((client) => client.id);
  await updateDoc(formRef, { clients, clientIds });
  return { id: formId, clients, clientIds };
});

// Update form details
export const updateForm = createAsyncThunk<Form, { formId: string; updates: Partial<Form> }>(
  'forms/updateForm',
  async ({ formId, updates }) => {
    const formRef = doc(DB, 'forms', formId);
    await updateDoc(formRef, updates);
    return { id: formId, ...updates } as Form;
  }
);

const formsAdapter = createEntityAdapter<Form>();

const initialState = formsAdapter.getInitialState({
  status: FETCH_STATUS_TYPES_ENUM.IDLE,
  searchStatus: FETCH_STATUS_TYPES_ENUM.IDLE,
  error: null,
  searchError: null,
  page: 0,
  lastVisibleDoc: null,
  sortBy: 'dateCreatedDesc',
  filters: {
    searchPhrase: '',
  },
} as { status: FETCH_STATUS_TYPES_ENUM; searchStatus: FETCH_STATUS_TYPES_ENUM; error: string | null; searchError: string | null; page: number; lastVisibleDoc: any | null; sortBy: string | null; filters: { searchPhrase: string } });

export const slice = createSlice({
  name: 'forms',
  initialState,
  reducers: {
    reset: () => initialState,
    sortFormsBy(state, action) {
      formsAdapter.removeAll(state);
      state.page = initialState.page;
      state.lastVisibleDoc = initialState.lastVisibleDoc;
      state.status = FETCH_STATUS_TYPES_ENUM.IDLE;
      state.sortBy = action.payload;
    },
    startSearch: (state) => {
      formsAdapter.removeAll(state);
      state.page = initialState.page;
      state.lastVisibleDoc = initialState.lastVisibleDoc;
      state.status = FETCH_STATUS_TYPES_ENUM.SEARCHING;
      state.searchStatus = FETCH_STATUS_TYPES_ENUM.SEARCHING;
      state.searchError = null;
    },
    endSearch: (state, action) => {
      state.filters.searchPhrase = action.payload;
      state.searchStatus = FETCH_STATUS_TYPES_ENUM.COMPLETED;
      state.status = FETCH_STATUS_TYPES_ENUM.IDLE;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchForms.pending, (state) => {
        state.status = FETCH_STATUS_TYPES_ENUM.LOADING;
      })
      .addCase(fetchForms.fulfilled, (state, action) => {
        const { lastVisibleDoc, page, forms: items } = action.payload;

        if (items.length !== 0) {
          formsAdapter.upsertMany(state, items);
          state.lastVisibleDoc = lastVisibleDoc;
          state.page = page;
          if (items.length < FETCH_LIMIT) {
            state.status = FETCH_STATUS_TYPES_ENUM.COMPLETED;
          } else {
            state.status = FETCH_STATUS_TYPES_ENUM.SUCCEEDED;
          }
        } else {
          state.status = FETCH_STATUS_TYPES_ENUM.COMPLETED;
        }
      })
      .addCase(fetchForms.rejected, (state, action) => {
        state.status = FETCH_STATUS_TYPES_ENUM.FAILED;
        state.error = action?.error?.message || null;
        console.error(action?.error);
      })
      .addCase(deleteForm.fulfilled, (state, action) => {
        const formId = action.payload;
        formsAdapter.removeOne(state, formId);
      })
      .addCase(updateForm.fulfilled, (state, action) => {
        const updatedForm = action.payload;
        formsAdapter.upsertOne(state, updatedForm);
      })
      .addCase(updateFormClients.fulfilled, (state, action) => {
        const { id, clients, clientIds } = action.payload;
        formsAdapter.updateOne(state, {
          id,
          changes: { clients, clientIds },
        });
      })
      .addCase(duplicateForm.fulfilled, (state, action) => {
        const newForm = action.payload;
        formsAdapter.upsertOne(state, newForm);
      })

      // External actions
      .addCase(saveForm.fulfilled, (state, action) => {
        const form = action.payload;
        formsAdapter.upsertOne(state, form);
      })
      .addCase(markFormSubmissionAsSeen.fulfilled, (state, action) => {
        const updatedFormSubmission = action.payload;
        const { formId } = updatedFormSubmission;

        const form = state.entities[formId];

        // Decrement the newResponses count
        if (form) {
          formsAdapter.updateOne(state, {
            id: formId,
            changes: {
              newResponses: form.newResponses - 1,
            },
          });
        }
      });
  },
});

export const { reset, sortFormsBy, startSearch, endSearch } = slice.actions;
export default slice.reducer;

export const {
  selectAll: selectAllForms,
  selectById: selectFormById,
  // Pass in a selector that returns the posts slice of state
} = formsAdapter.getSelectors<RootState>((state) => state.forms);

export const getFormsFetchStatus = (state: RootState) => state.forms.status;
export const getFormsFetchError = (state: RootState) => state.forms.error;
export const getFormsSearchStatus = (state: RootState) => state.forms.searchStatus;
export const getFormsSearchError = (state: RootState) => state.forms.searchError;
export const getFormsFilters = (state: RootState) => state.forms.filters;
export const getFormsSortBy = (state: RootState) => state.forms.sortBy;
