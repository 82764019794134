import { Suspense, lazy, ElementType } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// hooks
import useAuth from '../hooks/useAuth';
// layouts
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// guards
import GuestGuard from 'src/guards/GuestGuard';
import AuthGuard from 'src/guards/AuthGuard';
// config
import { PATH_AFTER_LOGIN } from '../config';
// components
import LoadingScreen from '../components/LoadingScreen';
import RoleBasedGuard from 'src/guards/RoleBasedGuard';
import { EXERCISES_PAGE_ENUM, USER_TYPE_ENUM } from 'src/@types/enums';
import { USER_ACCOUNT_PAGE_TYPES_ENUM } from 'src/pages/dashboard/profile/UserAccountPage';
import ProgramEditGuard from 'src/guards/ProgramEditGuard';
import DashboardGuard from 'src/guards/DashboardGuard';
import { StripeProvider } from 'src/contexts/StripeContext';
import { PromptProvider } from 'src/contexts/PromptContext';
import { StripeModalProvider } from 'src/contexts/StripeModalContext';

// ----------------------------------------------------------------------

const Loadable = (Component: ElementType) => (props: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { isAuthenticated } = useAuth();

  const isDashboard = pathname.includes('/dashboard') && isAuthenticated;

  return (
    <Suspense fallback={<LoadingScreen isDashboard={isDashboard} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: '/',
      element: <Navigate to="/dashboard/clients" replace />,
    },

    // Auth
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        {
          path: 'coach/register',
          element: (
            <GuestGuard>
              <CoachRegister />
            </GuestGuard>
          ),
        },
        {
          path: 'client/register',
          element: (
            <GuestGuard>
              <ClientRegister />
            </GuestGuard>
          ),
        },
        {
          path: 'client/register/:coachName/:email/:firstName/:lastName/:coachClientId',
          element: (
            <GuestGuard>
              <ClientRegister />
            </GuestGuard>
          ),
        },
        { path: 'reset-password', element: <ResetPassword /> },
      ],
    },

    // Coach Routes
    {
      path: 'coach',
      element: <LogoOnlyLayout />,
      children: [
        {
          path: 'request/:requestType/:coachName/:coachClientId/:email/:firstName/:lastName/:newUser',
          // path: 'request',
          element: <Request />,
        },
      ],
    },

    // Dashboard Routes
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardGuard>
            {/* <ClientSubscriptionGuard> */}
            {/* <OverduePaymentGuard> */}
            <StripeProvider>
              <PromptProvider>
                <StripeModalProvider>
                  <DashboardLayout />
                </StripeModalProvider>
              </PromptProvider>
            </StripeProvider>
            {/* </OverduePaymentGuard> */}
            {/* </ClientSubscriptionGuard> */}
          </DashboardGuard>
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: 'clients', element: <ClientsList /> },
        {
          path: 'coach-lydia-sign-ups',
          element: (
            <RoleBasedGuard accessibleRoles={[USER_TYPE_ENUM.COACH_LYDIA_ADMIN]}>
              <CoachLydiaSignUps />
            </RoleBasedGuard>
          ),
        },
        {
          path: 'coaches',
          element: (
            <RoleBasedGuard accessibleRoles={[USER_TYPE_ENUM.ADMIN]}>
              <CoachesList />
            </RoleBasedGuard>
          ),
        },
        { path: 'programs', element: <ProgramsList /> },
        { path: 'habits', element: <HabitsList /> },
        { path: 'mobile-app', element: <MobileApp /> },
        { path: 'report-an-issue', element: <ReportAnIssue /> },
        // { path: 'program', element: <ProgramCreate /> },
        // { path: 'exercises', element: <GeneralApp /> },

        {
          path: 'clients',
          children: [
            // { element: <Navigate to="/dashboard/clients" replace />, index: true },
            { path: 'list', element: <ClientsList /> },
            { path: 'profile/:id', element: <ClientProfile /> },
            { path: 'profile/:id/:tab', element: <ClientProfile /> },
            { path: 'profile/:id/workout-log/:workoutLogId', element: <ClientProfile /> },
            { path: 'profile/:id/:tab/:userHabitId', element: <ClientProfile /> },
            { path: 'client/edit/:id', element: <ClientEdit /> },
            { path: 'client/new', element: <ClientCreate /> },
          ],
        },
        {
          path: 'programs',
          children: [
            // { element: <Navigate to="/dashboard/programs" replace />, index: true },
            { path: 'list', element: <ProgramsList /> },
            { path: 'templates', element: <TemplatesList /> },
          ],
        },
        {
          path: 'program',
          children: [
            { element: <Navigate to="/dashboard/program/new" replace />, index: true },
            {
              path: ':id',
              element: (
                <ProgramEditGuard>
                  <Program />
                </ProgramEditGuard>
              ),
            },
            // /dashboard/program/template/1
            {
              path: 'template/:id',
              element: (
                <ProgramEditGuard template={true}>
                  <Program template={true} />
                </ProgramEditGuard>
              ),
            },
            // /dashboard/program/template/new
            {
              path: 'template/new',
              element: <ProgramCreate template={true} />,
            },
            { path: 'new', element: <ProgramCreate /> },
          ],
        },
        {
          path: 'exercises',
          children: [
            { element: <Navigate to="/dashboard/exercises/all" replace />, index: true },
            { path: 'all', element: <ExercisesList /> },
            {
              path: 'my-exercises',
              element: <ExercisesList pageType={EXERCISES_PAGE_ENUM.MY_EXERCISES} />,
            },
            {
              path: 'global',
              element: (
                <RoleBasedGuard accessibleRoles={[USER_TYPE_ENUM.ADMIN]}>
                  <ExercisesList pageType={EXERCISES_PAGE_ENUM.GLOBAL_EXERCISES} />
                </RoleBasedGuard>
              ),
            },
            { path: 'exercise/:id', element: <Exercise /> },
            { path: 'exercise/edit/:id', element: <ExerciseEdit /> },
            { path: 'exercise/new', element: <ExerciseCreate /> },
          ],
        },
        {
          path: 'habits',
          children: [
            { path: 'habit/edit/:id', element: <HabitEdit /> },
            { path: 'habit/new', element: <HabitCreate /> },
          ],
        },
        {
          path: 'requests',
          element: <FeatureRequestsPage />,
        },
        {
          path: 'forms',
          children: [{ element: <FormsPage />, index: true }],
        },
        {
          path: 'form',
          children: [
            { element: <Navigate to="/dashboard/forms" replace />, index: true },
            { path: ':id', element: <FormEditPage /> },
            { path: 'new', element: <FormCreatePage /> },
          ],
        },
        {
          path: 'profile',
          children: [
            { element: <UserAccountPage />, index: true },
            {
              path: 'billing',
              element: <UserAccountPage pageType={USER_ACCOUNT_PAGE_TYPES_ENUM.BILLING} />,
            },
          ],
        },
      ],
    },

    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: 'overdue-payment', element: <OverduePayment /> },
        { path: 'account-issues', element: <AccountIssues /> },
        { path: 'download', element: <Download /> },
        { path: 'pricing', element: <Pricing /> },
        { path: '500', element: <Page500 /> },
        { path: '404', element: <Page404 /> },
        { path: '403', element: <Page403 /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}

// AUTHENTICATION
const Login = Loadable(lazy(() => import('src/pages/auth/Login')));
const CoachRegister = Loadable(lazy(() => import('src/pages/auth/CoachRegister')));
const ClientRegister = Loadable(lazy(() => import('src/pages/auth/ClientRegister')));
const ResetPassword = Loadable(lazy(() => import('src/pages/auth/ResetPassword')));

// COACH
const Request = Loadable(lazy(() => import('src/pages/coach/Request')));

// PAYMENT
const OverduePayment = Loadable(lazy(() => import('src/pages/payment/OverduePayment')));

// ACCOUNT ISSUES
const AccountIssues = Loadable(lazy(() => import('src/pages/account-issues/AccountIssues')));

// Pricing
const Pricing = Loadable(lazy(() => import('src/pages/Pricing')));

// DASHBOARD

// Coaches
const CoachesList = Loadable(lazy(() => import('src/pages/dashboard/coaches/CoachesList')));

// Coach Lydia Sign Ups
const CoachLydiaSignUps = Loadable(
  lazy(() => import('src/pages/dashboard/coach-lydia/CoachLydiaSignUps'))
);

// Mobile App Builder
const MobileApp = Loadable(lazy(() => import('src/pages/dashboard/mobile-app/MobileApp')));

const ReportAnIssue = Loadable(
  lazy(() => import('src/pages/dashboard/report-an-issue/ReportAnIssuePage'))
);

// Clients
const ClientsList = Loadable(lazy(() => import('src/pages/dashboard/clients/ClientsList')));
const ClientProfile = Loadable(lazy(() => import('src/pages/dashboard/clients/ClientProfile')));
const ClientEdit = Loadable(lazy(() => import('src/pages/dashboard/clients/ClientEdit')));
const ClientCreate = Loadable(lazy(() => import('src/pages/dashboard/clients/ClientCreate')));

// Programs
const TemplatesList = Loadable(lazy(() => import('src/pages/dashboard/programs/TemplatesList')));
const ProgramsList = Loadable(lazy(() => import('src/pages/dashboard/programs/ProgramsList')));
const ProgramCreate = Loadable(lazy(() => import('src/pages/dashboard/programs/ProgramCreate')));
const Program = Loadable(lazy(() => import('src/pages/dashboard/programs/Program')));

// Exercises
const ExercisesList = Loadable(lazy(() => import('src/pages/dashboard/exercises/ExercisesList')));
const Exercise = Loadable(lazy(() => import('src/pages/dashboard/exercises/Exercise')));
const ExerciseEdit = Loadable(lazy(() => import('src/pages/dashboard/exercises/ExerciseEdit')));
const ExerciseCreate = Loadable(lazy(() => import('src/pages/dashboard/exercises/ExerciseCreate')));

// Habits
const HabitsList = Loadable(lazy(() => import('src/pages/dashboard/habits/HabitsList')));
const HabitEdit = Loadable(lazy(() => import('src/pages/dashboard/habits/HabitEdit')));
const HabitCreate = Loadable(lazy(() => import('src/pages/dashboard/habits/HabitCreate')));

// Feature Requests
const FeatureRequestsPage = Loadable(
  lazy(() => import('src/pages/dashboard/feature-requests/FeatureRequestsPage'))
);

// Forms
const FormsPage = Loadable(lazy(() => import('src/pages/dashboard/forms/FormsPage')));
const FormEditPage = Loadable(lazy(() => import('src/pages/dashboard/forms/FormEditPage')));
const FormCreatePage = Loadable(lazy(() => import('src/pages/dashboard/forms/FormCreatePage')));

// Profile
const UserAccountPage = Loadable(lazy(() => import('src/pages/dashboard/profile/UserAccountPage')));

// MAIN
const Download = Loadable(lazy(() => import('src/pages/Download')));
const Page500 = Loadable(lazy(() => import('src/pages/Page500')));
const Page403 = Loadable(lazy(() => import('src/pages/Page403')));
const Page404 = Loadable(lazy(() => import('src/pages/Page404')));
