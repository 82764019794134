import { useState } from 'react';
// @mui
import { Container, Tab, Tabs, Box } from '@mui/material';
// components
import Page from 'src/components/Page';
import Iconify from 'src/components/Iconify';
import HeaderBreadcrumbs from 'src/components/HeaderBreadcrumbs';
import useSettings from 'src/hooks/useSettings';
// sections
import { AccountGeneral } from 'src/sections/@dashboard/profile';
// import useCurrentUser from 'src/hooks/useCurrentUser';

// ----------------------------------------------------------------------

export enum USER_ACCOUNT_PAGE_TYPES_ENUM {
  GENERAL = 'general',
  BILLING = 'billing',
}

type Props = {
  pageType?: USER_ACCOUNT_PAGE_TYPES_ENUM;
};

export default function UserAccountPage({
  pageType = USER_ACCOUNT_PAGE_TYPES_ENUM.GENERAL,
}: Props) {
  const { themeStretch } = useSettings();
  // const { paymentMethod, clientSubscription } = useCurrentUser();

  const [currentTab, setCurrentTab] = useState(pageType);

  const TABS = [
    {
      value: 'general',
      label: 'General',
      icon: <Iconify icon="ic:round-account-box" />,
      component: <AccountGeneral />,
    },
    // {
    //   value: 'billing',
    //   label: 'Billing',
    //   icon: <Iconify icon="ic:round-receipt" />,
    //   component: (
    //     <AccountBilling paymentMethod={paymentMethod} clientSubscription={clientSubscription} />
    //   ),
    // },
    // {
    //   value: 'notifications',
    //   label: 'Notifications',
    //   icon: <Iconify icon="eva:bell-fill" />,
    //   component: <AccountNotifications />,
    // },
    // {
    //   value: 'social_links',
    //   label: 'Social links',
    //   icon: <Iconify icon="eva:share-fill" />,
    //   component: <AccountSocialLinks socialLinks={_userAbout.socialLinks} />,
    // },
    // {
    //   value: 'change_password',
    //   label: 'Change password',
    //   icon: <Iconify icon="ic:round-vpn-key" />,
    //   component: <AccountChangePassword />,
    // },
  ];

  return (
    <Page title="User Account">
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs heading="Account" links={[]} />

        <Tabs value={currentTab} onChange={(event, newValue) => setCurrentTab(newValue)}>
          {TABS.map((tab) => (
            <Tab key={tab.value} label={tab.label} icon={tab.icon} value={tab.value} />
          ))}
        </Tabs>

        {TABS.map(
          (tab) =>
            tab.value === currentTab && (
              <Box key={tab.value} sx={{ mt: 5 }}>
                {tab.component}
              </Box>
            )
        )}
      </Container>
    </Page>
  );
}
