import {
  createSlice,
  createEntityAdapter,
  createSelector,
  createAction,
  PayloadAction,
  createAsyncThunk,
} from '@reduxjs/toolkit';
import {
  ProgramWeek,
  Workout,
  WorkoutDragItem,
  WorkoutExercise,
  WorkoutExerciseGroup,
  WorkoutExerciseMetric,
  WorkoutExerciseMetricValue,
} from 'src/@types/program';
import {
  ChangeData,
  Changes,
  ProgramWeekFetchData,
  WorkoutChangeData,
} from 'src/@types/program_redux';
import { applyChanges } from 'src/redux/functions/applyChanges';
import { AppDispatch, RootState } from 'src/redux/store';
import handleDuplicateWeek from './functions/duplicate/handleDuplicateWeek';
import { programResetAction, updateCurrentTab } from './program';
import { FETCH_STATUS_TYPES_ENUM } from 'src/@types/enums';
import { handleCreateWeek } from './functions/create';
import { PROGRAM_WEEKS, WORKOUT_EXERCISE_GROUPS } from './constants/keys';
import { Unsubscribe } from 'firebase/firestore';
import { handleLoadProgramWeeks, patchBuggedPrograms } from './functions/load';
import { copyToCurrentProgram } from './copyToModal';

const programWeekAdapter = createEntityAdapter<ProgramWeek>({
  // Sort by index
  sortComparer: (a: ProgramWeek, b: ProgramWeek) => a.index - b.index,
});

const initialState = programWeekAdapter.getInitialState({
  status: FETCH_STATUS_TYPES_ENUM.IDLE,
  error: null,
  listener: null,
} as {
  status: FETCH_STATUS_TYPES_ENUM;
  error: string | null;
  listener: null | Unsubscribe;
});

export const addWeekAction = createAction<Changes>('programWeeks/addWeek');
export const updateWeekAction = createAction<Changes>('programWeeks/updateWeek');
export const removeWeekAction = createAction<Changes>('programWeeks/removeWeek');
export const reorderWeeksAction = createAction<Changes>('programWeeks/reorderWeeks');
export const duplicateWeekAction = createAction<Changes>('programWeeks/duplicateWeek');

export const fetchProgramWeeks = createAsyncThunk<
  ProgramWeekFetchData,
  { programId: string; template?: boolean }
>('programWeeks/fetchProgramWeeks', async ({ programId, template }) => {
  const fetchData: ProgramWeekFetchData = {
    programWeeks: [],
    workouts: [],
    workoutExerciseGroups: [],
    workoutExercises: [],
    workoutExerciseMetrics: [],
    workoutExerciseMetricValues: [],
  };

  const { programWeeks, workouts } = await handleLoadProgramWeeks({
    programId,
    isTemplate: template,
  });

  if (!programWeeks || !workouts) {
    throw new Error('Program weeks or workouts are undefined');
  }

  // Patch any program data issues
  const { programWeeks: patchedProgramWeeks, workouts: patchedWorkouts } =
    await patchBuggedPrograms({ programWeeks, workouts, template });

  fetchData.programWeeks = patchedProgramWeeks;

  patchedWorkouts.forEach((workout) => {
    const {
      id: workoutId,
      workoutExerciseGroups,
      workoutExercises,
      workoutExerciseMetrics,
      workoutExerciseMetricValues,
    } = workout;
    const workoutInfo = {
      id: workout.id,
      programWeekId: workout.programWeekId,
      index: workout.index,
      name: workout.name,
      description: workout.description,
      descriptionVisible: workout.description ? true : false,
      notes: workout.notes,
      dateCreated: workout.dateCreated,
      lastUpdated: workout.lastUpdated,
    } as Workout;
    fetchData.workouts.push(workoutInfo);
    fetchData.workoutExerciseGroups.push(
      ...workoutExerciseGroups.map((group) => ({ ...group, workoutId }))
    );
    fetchData.workoutExercises.push(
      ...workoutExercises.map((exercise) => ({
        ...exercise,
        workoutId,
        coachNotesVisible: exercise?.coachNotes ? true : false,
        headerVisible: exercise?.header ? true : false,
      }))
    );
    fetchData.workoutExerciseMetrics.push(
      ...workoutExerciseMetrics.map((item) => ({ ...item, workoutId }))
    );
    fetchData.workoutExerciseMetricValues.push(
      ...workoutExerciseMetricValues.map((item) => ({ ...item, workoutId }))
    );
  });

  return fetchData;
});

export const slice = createSlice({
  name: PROGRAM_WEEKS,
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers(builder) {
    const handleChangesReducer = (state: any, action: PayloadAction<Changes>) => {
      const items = action.payload.programWeeks;
      Object.keys(PROGRAM_WEEKS);
      if (items) {
        applyChanges(items, programWeekAdapter, state);
      }
    };

    builder
      .addCase(fetchProgramWeeks.pending, (state) => {
        state.status = FETCH_STATUS_TYPES_ENUM.LOADING;
        state.error = null;
      })
      .addCase(fetchProgramWeeks.fulfilled, (state, action) => {
        const items = action.payload[PROGRAM_WEEKS];
        if (items.length) {
          programWeekAdapter.addMany(state, items);
        }
        state.status = FETCH_STATUS_TYPES_ENUM.SUCCEEDED;
      })
      .addCase(fetchProgramWeeks.rejected, (state, action) => {
        state.status = FETCH_STATUS_TYPES_ENUM.FAILED;
        state.error = action?.error?.message ? action.error.message : null;
        console.error(action?.error);
      })

      .addCase(addWeekAction, handleChangesReducer)
      .addCase(updateWeekAction, handleChangesReducer)
      .addCase(removeWeekAction, handleChangesReducer)
      .addCase(reorderWeeksAction, handleChangesReducer)
      .addCase(duplicateWeekAction, handleChangesReducer)
      .addCase(copyToCurrentProgram, handleChangesReducer)

      // External
      .addCase(programResetAction, () => initialState);
  },
});

export const { reset } = slice.actions;

export default slice.reducer;

// ----------------------------------------------------------------------
// Thunks
// ----------------------------------------------------------------------

export const updateWeek =
  ({ id, updates }: { id: string; updates: Partial<ProgramWeek> }) =>
  (dispatch: AppDispatch) => {
    const update = { id: id, changes: updates };
    const changes: Changes = {
      programWeeks: {
        updated: [update],
      },
    };
    dispatch(updateWeekAction(changes));
  };

export const addWeek =
  ({ index }: { index: number }) =>
  (dispatch: AppDispatch, getState: () => RootState) => {
    const state = getState();
    const programId = state.program.id;

    if (!programId) {
      console.error('Program ID not found');
      return;
    }

    const programWeeks: ChangeData = {};

    const newWeek: ProgramWeek = handleCreateWeek({
      programId,
      index,
    });

    programWeeks.added = [newWeek];

    dispatch(addWeekAction({ programWeeks }));

    return newWeek.id;
  };

export const removeWeek =
  ({ weekId }: { weekId: string }) =>
  (dispatch: AppDispatch, getState: () => RootState) => {
    const programWeeks: ChangeData = {};
    const workouts: WorkoutChangeData = {
      programWeekId: weekId,
    };
    const workoutExerciseGroups: ChangeData = {};
    const workoutExercises: ChangeData = {};
    const workoutDragItems: ChangeData = {};
    const workoutExerciseMetrics: ChangeData = {};
    const workoutExerciseMetricValues: ChangeData = {};

    const state = getState();

    const week = selectProgramWeekById(state, weekId);

    if (!week) {
      console.error(`Program week with id ${weekId} not found`);
      return;
    }

    const { index, programId } = week;

    // Remove program week
    programWeeks.removed = [weekId];

    // Remove workouts
    const workoutIds = Object.values(state.workouts.entities)
      .filter((item): item is Workout => !!item && item?.programWeekId === weekId)
      .map((item) => item.id);
    workouts.removed = workoutIds;

    // Remove the exercise groups
    const workoutExerciseGroupIds = Object.values(state[WORKOUT_EXERCISE_GROUPS].entities)
      .filter((item): item is WorkoutExerciseGroup => !!item && workoutIds.includes(item.workoutId))
      .map((item) => item.id);
    workoutExerciseGroups.removed = workoutExerciseGroupIds;

    // Remove the workout exercises within the group
    const workoutExerciseIds = Object.values(state.workoutExercises.entities)
      .filter(
        (item): item is WorkoutExercise =>
          !!item && workoutExerciseGroupIds.includes(item.workoutExerciseGroupId)
      )
      .map((item) => item.id);
    workoutExercises.removed = workoutExerciseIds;

    // Remove all the workout exercises metrics for each exercise within the group
    const workoutExerciseMetricIds = Object.values(state.workoutExerciseMetrics.entities)
      .filter(
        (item): item is WorkoutExerciseMetric =>
          !!item && workoutExerciseIds.includes(item.workoutExerciseId)
      )
      .map((item) => item.id);
    workoutExerciseMetrics.removed = workoutExerciseMetricIds;

    // Remove all the workout exercise metric values for each exercise within the group
    const workoutExerciseMetricValueIds = Object.values(state.workoutExerciseMetricValues.entities)
      .filter(
        (item): item is WorkoutExerciseMetricValue =>
          !!item && workoutExerciseIds.includes(item.workoutExerciseId)
      )
      .map((item) => item.id);
    workoutExerciseMetricValues.removed = workoutExerciseMetricValueIds;

    // Remove all workout drag items
    const workoutDragItemIds = Object.values(state.workoutDragItems.entities)
      .filter((item): item is WorkoutDragItem => !!item && workoutIds.includes(item.workoutId))
      .map((item) => item.id);
    workoutDragItems.removed = workoutDragItemIds;

    // Find all the weeks after the removed week
    const affectedWeeks = Object.values(state.programWeeks.entities).filter(
      (item): item is ProgramWeek => !!item && item.index > index && item.programId === programId
    );
    // Update the indexes of weeks after this week
    const weekUpdates = affectedWeeks.map((item) => ({
      id: item.id,
      changes: {
        index: item.index - 1,
      },
    }));

    programWeeks.updated = weekUpdates;

    // Update the current tab
    const { currentTab } = state.program;

    // If we are currently on the removed week
    if (currentTab === weekId) {
      const weekInFront = Object.values(state.programWeeks.entities).find(
        (item): item is ProgramWeek =>
          !!item && item.index === index - 1 && item.programId === programId
      );
      const weekBehind = Object.values(state.programWeeks.entities).find(
        (item): item is ProgramWeek =>
          !!item && item.index === index + 1 && item.programId === programId
      );

      if (weekInFront) {
        dispatch(updateCurrentTab(weekInFront.id));
      } else if (weekBehind) {
        dispatch(updateCurrentTab(weekBehind.id));
      }
    }

    // Dispatch removed and updated data
    dispatch(
      removeWeekAction({
        programWeeks,
        workouts,
        workoutExerciseGroups,
        workoutExercises,
        workoutDragItems,
        workoutExerciseMetrics,
        workoutExerciseMetricValues,
      })
    );
  };

export const reorderWeeks =
  ({ from, to }: { from: number; to: number }) =>
  (dispatch: AppDispatch, getState: () => RootState) => {
    const programWeeks: ChangeData = {};

    // If from is greater than to then we moved the week left
    const movedDown = from > to;
    const movedUp = !movedDown;

    const state = getState();
    const movedWeek = Object.values(state.programWeeks.entities).find(
      (item): item is ProgramWeek => !!item && item.index === from
    );

    if (!movedWeek) {
      console.error(`Program week with index ${from} not found`);
      return;
    }

    // Update the index of the moved week
    programWeeks.updated = [
      {
        id: movedWeek.id,
        changes: {
          index: to,
        },
      },
    ];

    // Update the index for any week between the moved week and the destination
    const affectedWeeks = Object.values(state.programWeeks.entities).filter(
      (item): item is ProgramWeek => {
        // If the item is undefined or the index is equal to the moved week then skip
        if (!item || item.index === movedWeek.index) {
          return false;
        }

        // If moved down and the index is lesser than from and greater than or equal to to
        if (movedDown && item.index < from && item.index >= to) {
          return true;
        }
        // If moved up and the index is less than the from index and greater than the to index
        else if (movedUp && item.index > from && item.index <= to) {
          return true;
        }

        return false;
      }
    );

    const weekUpdates = affectedWeeks.map((item) => ({
      id: item.id,
      changes: {
        index: item.index + (movedDown ? 1 : -1),
      },
    }));

    programWeeks.updated.push(...weekUpdates);

    dispatch(reorderWeeksAction({ programWeeks }));
  };

export const duplicateWeek =
  ({ weekId }: { weekId: string }) =>
  (dispatch: AppDispatch, getState: () => RootState) => {
    const state = getState();
    const changes: Changes = {};

    handleDuplicateWeek({
      state,
      changes,
      weekId: weekId,
    });

    dispatch(duplicateWeekAction(changes));
  };

// ----------------------------------------------------------------------

// Export the customized selectors for this adapter using `getSelectors`
export const {
  selectAll: selectAllProgramWeeks,
  selectById: selectProgramWeekById,
  selectEntities: selectProgramWeekEntities,
  // Pass in a selector that returns the posts slice of state
} = programWeekAdapter.getSelectors((state: RootState) => state[PROGRAM_WEEKS]);

export const getWeekTabs = createSelector(selectAllProgramWeeks, (programWeeks) =>
  programWeeks.map((programWeek) => ({
    id: programWeek.id,
    index: programWeek.index,
    label: programWeek.name,
  }))
);

export const getProgramWeeksFetchStatus = (state: RootState) => state[PROGRAM_WEEKS].status;
export const getNumberOfWeeks = createSelector(
  selectAllProgramWeeks,
  (programWeeks) => programWeeks.length
);

// type Selector<S> = (state: RootState) => S;

// export const getProgramWeekFetchStatus = (id: string): Selector<string | undefined> =>
//   createSelector(
//     [(state: RootState) => selectProgramWeekById(state, id)],
//     (programWeek) => programWeek?.status
//   );
