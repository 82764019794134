// import { paramCase } from 'change-case';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Box, Button, Card, Link, Stack, Tooltip } from '@mui/material';
// @types
import { Program } from 'src/@types/program';
// components
// import Label from 'src/components/Label';
import Image from '../../../components/Image';
import { ProgramUsers } from '.';
import ProgramMenu from './ProgramMenu';
import { PATH_DASHBOARD } from 'src/routes/paths';
import { getUserId } from 'src/redux/slices/user';
import { useDispatch, useSelector } from 'src/redux/store';
import Label from 'src/components/Label';
import Iconify from 'src/components/Iconify';
import { convertProgramTemplateToProgram } from 'src/redux/slices/programTemplates';

// ----------------------------------------------------------------------

const OverlayStyle = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.common.black,
  top: 0,
  zIndex: 8,
  content: "''",
  width: '100%',
  height: '100%',
  position: 'absolute',
  opacity: 0.3,
}));

// ----------------------------------------------------------------------

type Props = {
  program: Program;
  assignProgramClients?: () => void;
  unassignClientFromProgram?: () => void;
  canDelete?: boolean;
  isTemplate?: boolean;
};

export default function ProgramCard({
  program,
  assignProgramClients,
  unassignClientFromProgram,
  canDelete,
  isTemplate,
}: Props) {
  const navigate = useNavigate();
  const userId = useSelector(getUserId);
  const dispatch = useDispatch();

  const { id, title, imageUrl, users, creatorIds } = program;
  const editable = userId && creatorIds.includes(userId);

  const handleClick = () => {
    if (editable) {
      if (isTemplate) {
        navigate(PATH_DASHBOARD.program.template(id));
      } else {
        navigate(PATH_DASHBOARD.program.root(id));
      }
    }
  };

  const handleUseTemplate = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    e.preventDefault();

    // Use
    const newId = (await (await dispatch(convertProgramTemplateToProgram(id))).payload) as string;
    navigate(PATH_DASHBOARD.program.root(newId));
  };

  const linkTo = PATH_DASHBOARD.program.root(id);
  return (
    <Card
      onClick={handleClick}
      sx={{
        cursor: editable ? 'pointer' : 'default',
        // warning colored border if template
        border: isTemplate ? '2px solid #FFC107' : '1px solid transparent',
      }}
    >
      <Box sx={{ position: 'relative' }}>
        <Image alt={title} src={imageUrl} ratio="16/9" />
        <OverlayStyle />
        {editable && (
          <ProgramMenu
            programId={id}
            featured={program.featured?.enabled}
            assignProgramClients={assignProgramClients}
            unassignClientFromProgram={unassignClientFromProgram}
            canDelete={canDelete}
            isTemplate={isTemplate}
          />
        )}

        {program?.featured?.enabled && (
          <Label
            variant="filled"
            color="info"
            sx={{ top: 16, left: 16, position: 'absolute', zIndex: 10 }}
          >
            Featured
          </Label>
        )}

        {/* {isTemplate && (
          <Label
            variant="filled"
            color="warning"
            sx={{ top: 0, left: 0, position: 'absolute', zIndex: 10 }}
          >
            Template
          </Label>
        )} */}
      </Box>

      <Stack spacing={2} sx={{ p: 3 }}>
        <Link variant="subtitle2" to={linkTo} color="inherit" noWrap component={RouterLink}>
          {title}
        </Link>

        {isTemplate && (
          <Stack direction="row" alignItems="flex-start">
            <Tooltip title="Use this template to create a new, assignable program." arrow>
              <Button
                onClick={handleUseTemplate}
                size="small"
                variant="outlined"
                startIcon={<Iconify icon="eva:checkmark-circle-fill" width={24} height={24} />}
                sx={{}}
              >
                Use
              </Button>
            </Tooltip>
            <Button
              size="small"
              variant="outlined"
              startIcon={<Iconify icon={'eva:edit-fill'} />}
              sx={{ ml: 1 }}
            >
              Edit
            </Button>
          </Stack>
        )}
        {!!users?.length && editable && !isTemplate && (
          <ProgramUsers programId={id} programUsers={users} />
        )}
      </Stack>
    </Card>
  );
}
