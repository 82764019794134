import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useMemo } from 'react';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { styled } from '@mui/material/styles';
import { LoadingButton } from '@mui/lab';
import { Card, Grid, Stack, Typography } from '@mui/material';
// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// hooks
import useClients from 'src/hooks/useClients';
// compressor
import Compressor from 'compressorjs';
// @types
import { Program } from 'src/@types/program';
import { ProgramUser } from 'src/@types/firebase';
// components
import {
  FormProvider,
  RHFAutocomplete,
  RHFTextField,
  RHFUploadSingleFile,
} from '../../../components/hook-form';
// Redux
import { saveProgramDetails } from 'src/redux/slices/program/program';
import { useDispatch } from 'src/redux/store';
import { CustomFile } from 'src/components/upload';
import Scrollbar from 'src/components/Scrollbar';
import ImageFormButton from './ImageFormButton';

// ----------------------------------------------------------------------

export const DEFAULT_PROGRAM_IMAGES = [
  'https://firebasestorage.googleapis.com/v0/b/workouts-67e5d.appspot.com/o/defaultProgramImages%2Fca83881d-39de-456f-8ad4-241a8572acb0.jpeg?alt=media&token=21328fca-1ff6-4529-bbba-2fab61cefe91',
  'https://firebasestorage.googleapis.com/v0/b/workouts-67e5d.appspot.com/o/defaultProgramImages%2F1a1080c7-92c7-4453-99a8-496ff731e5f7.jpeg?alt=media&token=3d8a8c99-6493-48e0-9215-0ff11a3d1bae',
  'https://firebasestorage.googleapis.com/v0/b/workouts-67e5d.appspot.com/o/defaultProgramImages%2F75eb601d-ad9d-4643-9634-860a65a9d55f.jpeg?alt=media&token=bb32f66f-2fcf-4ad2-8a14-6b6267501dea',
  'https://firebasestorage.googleapis.com/v0/b/workouts-67e5d.appspot.com/o/defaultProgramImages%2F2606e867-c282-4810-b237-5bb6521e2175.jpeg?alt=media&token=0e4f5bb9-790c-4991-9910-ac4ca85004f4',
  'https://firebasestorage.googleapis.com/v0/b/workouts-67e5d.appspot.com/o/defaultProgramImages%2F43738705-db03-4b34-96e0-45dbd515726d.jpeg?alt=media&token=c0bbb700-04ba-4672-be59-c493be1de874',
  'https://firebasestorage.googleapis.com/v0/b/workouts-67e5d.appspot.com/o/defaultProgramImages%2F65c660a5-f9a7-4ef9-90da-9778ca477d8c.jpeg?alt=media&token=883bf409-7785-44eb-9630-e6007d65363f',
  'https://firebasestorage.googleapis.com/v0/b/workouts-67e5d.appspot.com/o/defaultProgramImages%2F77e32ab4-021c-4c26-bee2-d4ba0b44d2f5.jpeg?alt=media&token=925ca3bc-4165-4282-80e6-132a6439ea25',
  'https://firebasestorage.googleapis.com/v0/b/workouts-67e5d.appspot.com/o/defaultProgramImages%2Fa2f6ca0c-f171-4e3f-bf1e-e8dd085f9f80.jpeg?alt=media&token=aa0dc9d2-0e7e-4dee-b654-a8d21a6c5fec',
  'https://firebasestorage.googleapis.com/v0/b/workouts-67e5d.appspot.com/o/defaultProgramImages%2Fc03df9a2-06be-47e4-9b65-23b4859cd5d7.jpeg?alt=media&token=23cf4397-b41d-492d-9c37-fd3e0f4cf373',
];

// ----------------------------------------------------------------------

const LabelStyle = styled(Typography)(({ theme }) => ({
  ...theme.typography.subtitle2,
  color: theme.palette.text.secondary,
  marginBottom: theme.spacing(1),
}));

// ----------------------------------------------------------------------

export type ProgramFormValuesProps = {
  title: string;
  description: string;
  users: ProgramUser[];
  // numberOfWeeks?: number;
  // numberOfWorkouts?: number;
  image: CustomFile | Blob | string | null;
};

type Props = {
  isEdit?: boolean;
  currentProgram?: Program;
  template?: boolean;
};

export default function ProgramNewEditForm({ isEdit, currentProgram, template }: Props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { enqueueSnackbar } = useSnackbar();

  const { clientsList: clients } = useClients();

  const NewProgramSchema = Yup.object().shape({
    title: Yup.string().required('Name is required'),
    image: Yup.mixed().required(template ? 'A image is required' : 'A program image is required'),
  });

  const defaultValues = useMemo(
    () => ({
      title: currentProgram?.title || '',
      description: currentProgram?.description || '',
      users: currentProgram?.users || [],
      image: currentProgram?.imageUrl || DEFAULT_PROGRAM_IMAGES[0],
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentProgram]
  );

  const methods = useForm<ProgramFormValuesProps>({
    resolver: yupResolver(NewProgramSchema),
    defaultValues,
  });

  const {
    reset,
    watch,
    // control,
    setValue,
    setError,
    // getValues,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const values = watch();

  const { image } = values;
  // Check if imageUrl is a string
  const imageString = typeof image === 'string' ? image : null;

  useEffect(() => {
    if (isEdit && currentProgram) {
      reset(defaultValues);
    }
    if (!isEdit) {
      reset(defaultValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit, currentProgram]);

  const onSubmit = async (data: ProgramFormValuesProps) => {
    try {
      const savedProgram = (await (
        await dispatch(saveProgramDetails({ data, isEdit, isTemplate: template }))
      ).payload) as Program;

      enqueueSnackbar(!isEdit ? 'Create success!' : 'Update success!');
      if (!isEdit) {
        reset();

        if (template) {
          navigate(PATH_DASHBOARD.program.template(savedProgram.id));
        } else {
          navigate(PATH_DASHBOARD.program.root(savedProgram.id));
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleDrop = useCallback(
    (acceptedFiles: File[]) => {
      const file = acceptedFiles[0];

      if (file) {
        new Compressor(file, {
          quality: 0.7,
          maxWidth: 1500,
          maxHeight: 1000,
          success: (result: any) => {
            // If size is greater than 300kb (300 * 1024) set error for file field
            if (result.size > 300 * 1024) {
              const fileSizeKb = Math.round(result.size / 1024);
              setError('image', {
                type: 'maxSize',
                message: `The size of your image once compressed (${fileSizeKb}kb / 300kb) was too big. Try reducing the size of your image before uploading again.`,
              });
            } else if (result) {
              setValue(
                'image',
                Object.assign(result, {
                  preview: URL.createObjectURL(result),
                })
              );
            }
          },
        });
      }
    },
    [setValue, setError]
  );

  const handleImageSelect = (imageUrl: string) => {
    setValue('image', imageUrl);
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Grid container>
        <Grid item xs={12} md={8}>
          <Card sx={{ p: 3, overflow: 'visible' }}>
            <Stack spacing={3}>
              <RHFTextField name="title" label={template ? 'Template Name' : 'Program Name'} />

              <div>
                <LabelStyle>Description</LabelStyle>
                <RHFTextField
                  name="description"
                  multiline
                  label={template ? 'Template Description' : 'Program Description'}
                />
              </div>

              {!template && (
                <RHFAutocomplete name="users" label="Assign to Client" options={clients} />
              )}

              {/* {!isEdit && (
                <div>
                  <LabelStyle>How long is the program?</LabelStyle>
                  <Stack direction="row" alignItems="center">
                    <RHFTextField name="numberOfWorkouts" placeholder="4" />
                    <Typography variant="caption" sx={{ mx: 2 }}>
                      sessions
                    </Typography>
                    <Typography variant="caption" sx={{ mr: 2 }}>
                      x
                    </Typography>
                    <RHFTextField name="numberOfWeeks" placeholder="4" />
                    <Typography variant="caption" sx={{ ml: 2 }}>
                      weeks
                    </Typography>
                  </Stack>
                </div>
              )} */}

              <div>
                <LabelStyle>Image</LabelStyle>

                <Typography
                  variant="caption"
                  color="text.secondary"
                  sx={{ mb: 1, display: 'block' }}
                >
                  Select an image to be a thumbnail for your program (clients will see this
                  thumbnail on their mobile app)
                </Typography>

                <Scrollbar>
                  <Stack
                    direction="row"
                    alignItems="flex-start"
                    spacing={2}
                    // sx={{ overflowY: 'hidden' }}
                  >
                    {DEFAULT_PROGRAM_IMAGES.map((image) => (
                      <ImageFormButton
                        key={image}
                        imageUrl={image}
                        selected={imageString === image}
                        onSelect={() => handleImageSelect(image)}
                      />
                    ))}
                  </Stack>
                </Scrollbar>

                <Typography
                  variant="caption"
                  color="text.secondary"
                  sx={{ mt: 2, mb: 1, display: 'block' }}
                >
                  Or upload your own image
                </Typography>

                <RHFUploadSingleFile
                  name="image"
                  accept={{ 'image/jpeg': [] }}
                  maxSize={5242880} // 5MB
                  onDrop={handleDrop}
                  // onRemoveAll={handleRemoveAll}
                />
              </div>

              <LoadingButton type="submit" variant="contained" size="large" loading={isSubmitting}>
                {!isEdit ? (template ? 'Create Template' : 'Create Program') : 'Save Changes'}
              </LoadingButton>
            </Stack>
          </Card>
        </Grid>
      </Grid>
    </FormProvider>
  );
}
