import { addDoc, collection, doc, getDoc, writeBatch } from 'firebase/firestore';
import {
  ProgramTemplate,
  ProgramTemplate_WithID,
  ProgramWeek_WithID,
  Workout_WithID,
} from 'src/@types/firebase';
import { DB } from 'src/contexts/FirebaseContext';
import convertFirebaseDataDates from 'src/utils/convertFirebaseDataDates';
import uuidv4 from 'src/utils/uuidv4';
import { handleLoadProgramWeeks } from '../load';
import cleanProgramData from 'src/utils/cleanProgramData';

type Props = {
  programTemplateId: string;
};

// --------------------------------------------------
// Duplicate a program
// --------------------------------------------------
// Duplicate a program
// Duplicate all program weeks
// Duplicate all workouts
// --------------------------------------------------
const handleDuplicateProgramTemplate = async ({ programTemplateId }: Props) => {
  // --------------------------------------------------
  // Load all program content locally
  // --------------------------------------------------
  // Fetch Program from Firebase
  const programTemplateDocRef = doc(DB, 'programTemplates', programTemplateId);
  const programTemplateSnap = await getDoc(programTemplateDocRef);

  if (!programTemplateSnap.exists()) {
    throw new Error(`Program Template with id ${programTemplateId} does not exist`);
  }

  const data = programTemplateSnap.data();
  const { id } = programTemplateSnap;

  convertFirebaseDataDates(data);

  const programTemplate = { ...data, id } as ProgramTemplate_WithID;

  // Fetch Program Weeks from Firebase
  // Fetch Workouts from Firebase
  const { programWeeks, workouts } = await handleLoadProgramWeeks({
    programId: programTemplateId,
    isTemplate: true,
  });

  if (!programWeeks || !workouts) {
    throw new Error('Program weeks or workouts are undefined');
  }

  // --------------------------------------------------
  // Duplicate all program content locally
  // --------------------------------------------------

  // Duplicate Program
  const cleanProgramTemplate = cleanProgramData(programTemplate);
  const newProgramTemplateData: ProgramTemplate & { id?: string } = {
    ...cleanProgramTemplate,
    id: undefined,
    users: [],
    userIds: [],
    dateCreated: new Date(),
    lastUpdated: new Date(),
  };

  // Remove featured status from duplicated program
  if (newProgramTemplateData?.featured) delete newProgramTemplateData.featured;

  delete newProgramTemplateData.id;

  const newProgramTemplateRef = await addDoc(
    collection(DB, 'programTemplates'),
    newProgramTemplateData
  );
  const newProgramTemplateId = newProgramTemplateRef.id;
  const newProgramTemplate: ProgramTemplate_WithID = {
    ...newProgramTemplateData,
    id: newProgramTemplateId,
  };
  if (!newProgramTemplateId) {
    throw new Error('Failed to duplicate program');
  }

  // Duplicate Program Weeks
  const newWorkouts: Workout_WithID[] = [];
  const newProgramWeeks = programWeeks.map((programWeek) => {
    const programWeekWorkouts = workouts.filter(
      (workout) => workout.programWeekId === programWeek.id
    );

    const newProgramWeek: ProgramWeek_WithID = {
      ...programWeek,
      id: uuidv4(),
      programId: newProgramTemplateId,
    };

    // Duplicate Workouts
    programWeekWorkouts.forEach((workout) => {
      const newWorkout: Workout_WithID = {
        ...workout,
        id: uuidv4(),
        programWeekId: newProgramWeek.id,
        programId: newProgramTemplateId,
      };
      newWorkouts.push(newWorkout);
    });

    return newProgramWeek;
  });

  // --------------------------------------------------
  // Save the duplicated program content to Firebase
  // --------------------------------------------------

  const batch = writeBatch(DB);

  // Save Program Weeks
  newProgramWeeks.forEach((programWeek) => {
    const { id: programWeekId, ...programWeekData } = programWeek;
    batch.set(
      doc(DB, 'programTemplates', newProgramTemplateId, 'programWeeks', programWeekId),
      programWeekData
    );
  });

  // Save Workouts
  newWorkouts.forEach((workout) => {
    const { id: workoutId, ...workoutData } = workout;
    batch.set(
      doc(
        DB,
        'programTemplates',
        newProgramTemplateId,
        'programWeeks',
        workout.programWeekId,
        'workouts',
        workoutId
      ),
      workoutData
    );
  });

  await batch.commit();

  return newProgramTemplate;
};

export default handleDuplicateProgramTemplate;
