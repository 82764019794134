// ----------------------------------------------------------------------
import { CLIENT_PROFILE_TABS, EXERCISES_PAGE_ENUM } from 'src/@types/enums';

function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';
const ROOTS_COACH_CLIENT = '/coach';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  clientRegister: path(ROOTS_AUTH, '/client/register'),
  coachRegister: path(ROOTS_AUTH, '/coach/register'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
};

export const PATH_PAGE = {
  // comingSoon: '/coming-soon',
  // maintenance: '/maintenance',
  // pricing: '/pricing',
  // payment: '/payment',
  // about: '/about-us',
  // contact: '/contact-us',
  // faqs: '/faqs',
  page403: '/403',
  page404: '/404',
  // page500: '/500',
  // components: '/components',
  download: '/download',
  pricing: '/pricing',
  overduePayment: '/overdue-payment',
  accountIssues: '/account-issues',
};

export const PATH_COACH_CLIENT = {
  accept: path(ROOTS_COACH_CLIENT, '/request/accept'),
  decline: path(ROOTS_COACH_CLIENT, '/request/decline'),
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  clients: {
    root: path(ROOTS_DASHBOARD, '/clients'),
    profile: (id: string) => path(ROOTS_DASHBOARD, `/clients/profile/${id}`),
    profileTab: (id: string, tab: CLIENT_PROFILE_TABS) =>
      path(ROOTS_DASHBOARD, `/clients/profile/${id}/${tab}`),
    profileWorkoutLog: (id: string, workoutLogId: string) =>
      path(ROOTS_DASHBOARD, `/clients/profile/${id}/workout-log/${workoutLogId}`),
    profileUserHabit: (id: string, userHabitId: string, tab = CLIENT_PROFILE_TABS.HABITS) =>
      path(ROOTS_DASHBOARD, `/clients/profile/${id}/${tab}/${userHabitId}`),
    edit: (id: string) => path(ROOTS_DASHBOARD, `/clients/client/edit/${id}`),
    new: path(ROOTS_DASHBOARD, '/clients/client/new'),
  },
  'coach-lydia-sign-ups': {
    root: path(ROOTS_DASHBOARD, '/coach-lydia-sign-ups'),
  },
  coaches: {
    root: path(ROOTS_DASHBOARD, '/coaches'),
  },
  programs: {
    root: path(ROOTS_DASHBOARD, '/programs'),
    templates: path(ROOTS_DASHBOARD, '/programs/templates'),
  },
  program: {
    root: (id: string) => path(ROOTS_DASHBOARD, `/program/${id}`),
    template: (id: string) => path(ROOTS_DASHBOARD, `/program/template/${id}`),
    templateNew: path(ROOTS_DASHBOARD, '/program/template/new'),
    new: path(ROOTS_DASHBOARD, '/program/new'),
  },
  exercises: {
    root: (page: EXERCISES_PAGE_ENUM) => path(ROOTS_DASHBOARD, `/exercises/${page}`),
    myExercises: path(ROOTS_DASHBOARD, '/exercises/my-exercises'),
    globalExercises: path(ROOTS_DASHBOARD, '/exercises/global'),
    exercise: (id: string) => path(ROOTS_DASHBOARD, `/exercises/exercise/${id}`),
    edit: (id: string) => path(ROOTS_DASHBOARD, `/exercises/exercise/edit/${id}`),
    new: path(ROOTS_DASHBOARD, '/exercises/exercise/new'),
  },
  habits: {
    root: path(ROOTS_DASHBOARD, '/habits'),
    edit: (id: string) => path(ROOTS_DASHBOARD, `/habits/habit/edit/${id}`),
    new: path(ROOTS_DASHBOARD, '/habits/habit/new'),
  },
  'mobile-app': {
    root: path(ROOTS_DASHBOARD, '/mobile-app'),
  },
  requests: {
    root: path(ROOTS_DASHBOARD, '/requests'),
  },
  forms: {
    root: path(ROOTS_DASHBOARD, '/forms'),
    edit: (id: string) => path(ROOTS_DASHBOARD, `/form/${id}`),
    new: path(ROOTS_DASHBOARD, '/form/new'),
  },
  'report-an-issue': {
    root: path(ROOTS_DASHBOARD, '/report-an-issue'),
  },
  profile: {
    root: path(ROOTS_DASHBOARD, '/profile'),
    billing: path(ROOTS_DASHBOARD, '/profile/billing'),
  },
};

export const PATH_DOCS = 'https://docs-minimals.vercel.app/introduction';
