// @mui
import { styled } from '@mui/material/styles';
import { Box, Typography, LinearProgress, Grid } from '@mui/material';
import { useSelector } from 'react-redux';
import { getUser } from 'src/redux/slices/user';
import { User_WithID } from 'src/@types/firebase';

// Styled Badge Container
const BadgeContainer = styled(Box)(({ theme, bgcolor }: { theme?: any; bgcolor: string }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: bgcolor,
  color: theme.palette.primary.contrastText,
  borderRadius: '50%',
  width: 35,
  height: 35,
  marginBottom: theme.spacing(2),
}));

// Badge Component
const Badge = ({
  level,
  clients,
  discount,
  color,
  grayed,
}: {
  level: string;
  clients: string;
  discount: string;
  color: string;
  grayed: boolean;
}) => {
  const getBadgeEmoji = (level: string) => {
    switch (level) {
      case 'Legendary':
        return '🏆';
      case 'Elite':
        return '💎';
      case 'Advanced':
        return '🏅';
      case 'Pro':
        return '🥈';
      case 'Starter':
        return '🆓';
      default:
        return '⭐';
    }
  };

  return (
    <Box
      sx={{
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',

        // grayscale if the user doesnt have enough clients
        // filter: grayed ? 'grayscale(100%)' : 'none',
        position: 'relative',
      }}
    >
      {/* Locked overlay */}
      {grayed && (
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'background.paper',
            opacity: 0.8,
            borderRadius: 0.5,
            zIndex: 1,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography
            variant="body2"
            sx={{
              // rorate the text
              transform: 'rotate(-10deg)',
              // bold
              fontWeight: 900,
              // red
              // color: 'error.main',
            }}
          >
            Locked
          </Typography>
        </Box>
      )}

      {/* Badge Icon */}
      <BadgeContainer bgcolor={color}>
        <Typography variant="body2">{getBadgeEmoji(level)}</Typography>
      </BadgeContainer>

      {/* Level Name */}
      <Typography variant="body2" sx={{ color }}>
        {level}
      </Typography>

      {/* Clients and Discount Info */}
      {/* <Typography variant="caption" fontWeight={900}>
        {discount}
      </Typography> */}
      <Typography variant="caption">Clients: {clients}</Typography>
    </Box>
  );
};

// Leveling System
function CoachLevels({ user }: { user: User_WithID }) {
  const { activeClients, firstName, lastName } = user;
  const name = `${firstName} ${lastName}`;
  const activeClientsCount = user.activeClients ? user.activeClients : 0;

  // Determine level based on client count
  const getLevel = (clientCount: number) => {
    if (clientCount > 30) return 'Legendary';
    if (clientCount > 20) return 'Elite';
    if (clientCount > 10) return 'Advanced';
    if (clientCount > 3) return 'Pro';
    return 'Starter';
  };

  // Return target and remaining clients for next level
  const getProgressTowardsNextLevel = (clientCount: number) => {
    if (clientCount >= 20)
      return { target: 31, remaining: 31 - clientCount, nextLevel: 'Legendary' };
    if (clientCount >= 10) return { target: 21, remaining: 21 - clientCount, nextLevel: 'Elite' };
    if (clientCount >= 4) return { target: 11, remaining: 11 - clientCount, nextLevel: 'Advanced' };
    return { target: 4, remaining: 4 - clientCount, nextLevel: 'Pro' };
  };

  const level: 'Starter' | 'Pro' | 'Advanced' | 'Elite' | 'Legendary' =
    getLevel(activeClientsCount);
  const nextLevelProgress = getProgressTowardsNextLevel(activeClientsCount);

  return (
    <>
      <Typography variant="h4">{name}</Typography>
      <Typography variant="caption">{level} Coach</Typography>
      <Typography variant="body2" sx={{ my: 2 }}>
        Total <b>active clients</b> this month: <b>{activeClients}</b>
      </Typography>
      {activeClientsCount < 31 && (
        <>
          <LinearProgress
            variant="determinate"
            value={(activeClientsCount / nextLevelProgress.target) * 100}
            sx={{
              mt: 2,
              width: 'full',
              height: 10,
              borderRadius: 5,
              bgcolor: 'grey.500',
            }}
          />
          <Typography
            variant="caption"
            sx={{
              mt: 1,
              color: 'text.secondary',
            }}
          >
            +{nextLevelProgress.remaining} client{nextLevelProgress.remaining > 1 ? 's' : ''} until{' '}
            {nextLevelProgress.nextLevel}
          </Typography>
        </>
      )}
    </>
  );
}

// Main Page
export default function UserAwards() {
  const user = useSelector(getUser);

  return (
    <Box sx={{ p: 2 }}>
      <CoachLevels user={user} />

      <Typography variant="subtitle2" sx={{ mt: 5 }}>
        Coaching Level
      </Typography>
      <Grid container spacing={2} sx={{ mb: 1, mt: 1 }}>
        <Grid item xs={4}>
          <Badge level="Starter" clients="1-3" discount="Free" color="#8a96a2" grayed={false} />
        </Grid>
        <Grid item xs={4}>
          <Badge
            level="Pro"
            clients="4-10"
            discount=""
            color="#5a8236"
            grayed={user?.activeClients ? user.activeClients < 4 : true}
          />
        </Grid>
        <Grid item xs={4}>
          <Badge
            level="Advanced"
            clients="11-20"
            discount="28% off"
            color="#2b5b99"
            grayed={user?.activeClients ? user.activeClients < 11 : true}
          />
        </Grid>
        <Grid item xs={4}>
          <Badge
            level="Elite"
            clients="21-30"
            discount="57% off"
            color="#6b84b4"
            grayed={user?.activeClients ? user.activeClients < 21 : true}
          />
        </Grid>
        <Grid item xs={4}>
          <Badge
            level="Legendary"
            clients="31+"
            discount="71% off"
            color="#b58b00"
            grayed={user?.activeClients ? user.activeClients < 31 : true}
          />
        </Grid>
      </Grid>
    </Box>
  );
}
