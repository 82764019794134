// @mui
import { Box } from '@mui/material';
// @type
import { Program } from 'src/@types/program';
// hooks
import useProgramClientModal from 'src/hooks/useProgramClientModal';
// components
import { SkeletonProductItem } from '../../../components/skeleton';
//
import ProgramCard from './ProgramCard';

// ----------------------------------------------------------------------

type Props = {
  programs: Program[];
  loading: boolean;
  templates?: boolean;
};

export default function ProgramsList({ programs, loading, templates }: Props) {
  const { openProgramClientModal } = useProgramClientModal();

  const handleAssign = (programId: string) => {
    openProgramClientModal(programId);
  };

  return (
    <Box
      sx={{
        display: 'grid',
        gap: 3,
        gridTemplateColumns: {
          xs: 'repeat(1, 1fr)',
          sm: 'repeat(2, 1fr)',
          md: 'repeat(3, 1fr)',
          lg: 'repeat(4, 1fr)',
        },
      }}
    >
      {(loading ? [...programs, ...Array(12)] : programs).map((program, index) =>
        program ? (
          <ProgramCard
            key={program.id}
            assignProgramClients={() => handleAssign(program.id)}
            program={program}
            canDelete={true}
            isTemplate={templates}
          />
        ) : (
          <SkeletonProductItem key={index} />
        )
      )}
    </Box>
  );
}
