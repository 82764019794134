import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FETCH_STATUS_TYPES_ENUM, FIELD_TYPES } from 'src/@types/enums';
import { collection, doc, getDoc, setDoc, Timestamp, updateDoc } from 'firebase/firestore';
import { DB } from 'src/contexts/FirebaseContext';
import uuidv4 from 'src/utils/uuidv4';
import { Form, FormField } from 'src/@types/firebase';
import { RootState } from '../store';
import { markFormSubmissionAsSeen } from './formSubmissions';
import { updateForm, updateFormClients } from './forms';

export enum FORM_BUILDER_PAGES {
  BUILDER = 'builder',
  RESPONSES = 'responses',
  PREVIEW = 'preview',
}

const initialState: {
  form: Form;
  page: FORM_BUILDER_PAGES;
  hasChanged: boolean;
  initialized: boolean;
  fetchStatus: FETCH_STATUS_TYPES_ENUM;
  fetchError: string | null;
  saveStatus: FETCH_STATUS_TYPES_ENUM;
  saveError: string | null;
} = {
  form: {
    title: '',
    dateCreated: Timestamp.now(),
    lastUpdated: Timestamp.now(),
    description: '',
    recurring: false,
    recurringFrequency: 1,
    recurringUnit: 'day',
    recurringStartDate: Timestamp.now(),
    shareWith: 'clients',
    creatorId: '',
    clientIds: [],
    clients: [],
    fields: [],
    newResponses: 0,
  },
  page: FORM_BUILDER_PAGES.BUILDER,
  hasChanged: false,
  initialized: false,
  fetchStatus: FETCH_STATUS_TYPES_ENUM.IDLE,
  fetchError: null,
  saveStatus: FETCH_STATUS_TYPES_ENUM.IDLE,
  saveError: null,
};

export const fetchForm = createAsyncThunk<Form, string>('form/fetchForm', async (formId) => {
  const docRef = doc(DB, 'forms', formId);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    const { id } = docSnap;
    const data = docSnap.data();
    return { id, ...data } as Form;
  } else {
    throw new Error('No such form!');
  }
});

export const saveForm = createAsyncThunk<Form>('form/saveForm', async (_, { getState }) => {
  const state = getState() as RootState;

  const { form } = state.formBuilder;

  // Check for creatorId
  const userId = state.user.id;

  if (!userId) {
    throw new Error('save form: user id not found');
  }

  if (!form.id) {
    const docRef = doc(collection(DB, 'forms'));
    const { id } = docRef;

    // change the url without reloading the page
    window.history.pushState({}, '', `/dashboard/form/${id}`);

    const newForm = {
      ...form,
      id,
      dateCreated: Timestamp.now(),
      lastUpdated: Timestamp.now(),
      creatorId: userId,
    };

    await setDoc(docRef, newForm);
    return newForm;
  } else {
    const docRef = doc(DB, 'forms', form.id);
    await updateDoc(docRef, {
      ...form,
      lastUpdated: Timestamp.now(),
      creatorId: userId,
    });
    return form;
  }
});

export const slice = createSlice({
  name: 'formBuilder',
  initialState,
  reducers: {
    updatePage: (state, action: PayloadAction<FORM_BUILDER_PAGES>) => {
      state.page = action.payload;
    },
    setForm: (state, action: PayloadAction<Form>) => {
      state.form = action.payload;
      state.fetchError = null;
      state.fetchStatus = FETCH_STATUS_TYPES_ENUM.SUCCEEDED;
      state.initialized = true;
      state.hasChanged = true;
    },
    reset: () => initialState,
    updateTitle: (state, action: PayloadAction<string>) => {
      state.form.title = action.payload;
      state.hasChanged = true;
    },
    updateDescription: (state, action: PayloadAction<string>) => {
      state.form.description = action.payload;
      state.hasChanged = true;
    },
    updateRecurring: (state, action: PayloadAction<boolean>) => {
      const recurring = action.payload;
      state.form.recurring = recurring;
      if (recurring) {
        state.form.recurringFrequency = 1;
        state.form.recurringUnit = 'day';
        state.form.recurringStartDate = Timestamp.now();
      }

      state.hasChanged = true;
    },
    updateRecurringFrequency: (state, action: PayloadAction<number>) => {
      state.form.recurringFrequency = action.payload;
      state.hasChanged = true;
    },
    updateRecurringUnit: (state, action: PayloadAction<'day' | 'week' | 'month'>) => {
      state.form.recurringUnit = action.payload;
      state.hasChanged = true;
    },
    updateRecurringStartDate: (state, action: PayloadAction<Date>) => {
      state.form.recurringStartDate = Timestamp.fromDate(action.payload);
      state.hasChanged = true;
    },
    updateShareWith: (state, action: PayloadAction<'clients' | 'public'>) => {
      state.form.shareWith = action.payload;
      state.hasChanged = true;
    },
    addField: (state, action: PayloadAction<FIELD_TYPES>) => {
      const type = action.payload;
      const newField: FormField = {
        id: uuidv4(),
        index: state.form.fields.length,
        type,
        question: '',
        required: false,
      };

      if (
        [FIELD_TYPES.MULTIPLE_CHOICE, FIELD_TYPES.CHECKBOXES, FIELD_TYPES.MULTIPLE_SELECT].includes(
          type
        )
      ) {
        newField.options = ['Option 1'];
      }

      if (type === FIELD_TYPES.RATING) {
        newField.maxRating = 5;
      }

      state.form.fields.push(newField);
      state.hasChanged = true;
    },
    duplicateField: (state, action: PayloadAction<string>) => {
      const id = action.payload;
      const field = state.form.fields.find((field) => field.id === id);
      if (!field) return;

      const newField: FormField = {
        ...field,
        id: uuidv4(),
        index: field.index + 1,
      };

      state.form.fields.forEach((f) => {
        if (f.index >= newField.index) f.index++;
      });

      state.form.fields.splice(newField.index, 0, newField);
      state.hasChanged = true;
    },
    deleteField: (state, action: PayloadAction<string>) => {
      state.form.fields = state.form.fields.filter((field) => field.id !== action.payload);
      // Re-index fields
      state.form.fields.forEach((field, index) => {
        field.index = index;
      });

      state.hasChanged = true;
    },
    updateField: (state, action: PayloadAction<{ id: string; data: Partial<FormField> }>) => {
      const { id, data } = action.payload;
      const field = state.form.fields.find((field) => field.id === id);
      if (field) {
        Object.assign(field, data);
        state.hasChanged = true;
      }
    },
    reorderFields: (
      state,
      action: PayloadAction<{ sourceIndex: number; destinationIndex: number }>
    ) => {
      const { sourceIndex, destinationIndex } = action.payload;
      const [movedField] = state.form.fields.splice(sourceIndex, 1);
      state.form.fields.splice(destinationIndex, 0, movedField);

      state.form.fields.forEach((field, index) => {
        field.index = index;
      });

      state.hasChanged = true;
    },
    updateQuestion: (state, action: PayloadAction<{ fieldId: string; question: string }>) => {
      const { fieldId, question } = action.payload;
      const field = state.form.fields.find((field) => field.id === fieldId);
      if (field) {
        field.question = question;
        state.hasChanged = true;
      }
    },
    toggleRequired: (state, action: PayloadAction<string>) => {
      const id = action.payload;
      const field = state.form.fields.find((field) => field.id === id);
      if (field) {
        field.required = !field.required;
        state.hasChanged = true;
      }
    },
    addUpdateOptions: (state, action: PayloadAction<{ fieldId: string; options: string[] }>) => {
      const { fieldId, options } = action.payload;
      const field = state.form.fields.find((field) => field.id === fieldId);
      if (field) {
        field.options = options;
        state.hasChanged = true;
      }
    },
    updateMaxRating: (state, action: PayloadAction<{ fieldId: string; maxRating: number }>) => {
      const { fieldId, maxRating } = action.payload;
      const field = state.form.fields.find((field) => field.id === fieldId);
      if (field) {
        field.maxRating = maxRating;
        state.hasChanged = true;
      }
    },
    changeFieldType: (state, action: PayloadAction<{ fieldId: string; type: FIELD_TYPES }>) => {
      const { fieldId, type } = action.payload;
      const field = state.form.fields.find((field) => field.id === fieldId);
      if (field) {
        if (field?.options) {
          if (
            type !== FIELD_TYPES.MULTIPLE_CHOICE &&
            type !== FIELD_TYPES.CHECKBOXES &&
            type !== FIELD_TYPES.MULTIPLE_SELECT
          ) {
            delete field.options;
          }
        } else if (
          type === FIELD_TYPES.MULTIPLE_CHOICE ||
          type === FIELD_TYPES.CHECKBOXES ||
          type === FIELD_TYPES.MULTIPLE_SELECT
        ) {
          field.options = ['Option 1'];
        }

        if (field?.maxRating) {
          if (type !== FIELD_TYPES.RATING) {
            delete field.maxRating;
          }
        } else if (type === FIELD_TYPES.RATING) {
          field.maxRating = 5;
        }

        field.type = type;
        state.hasChanged = true;
      }
    },
    addUpdateClients: (
      state,
      action: PayloadAction<{
        clients: { id: string; firstName: string; lastName: string; profilePictureUrl: string }[];
      }>
    ) => {
      const { clients } = action.payload;
      state.form.clients = clients;
      state.form.clientIds = clients.map((c) => c.id);
      state.hasChanged = true;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchForm.pending, (state) => {
        state.fetchStatus = FETCH_STATUS_TYPES_ENUM.LOADING;
      })
      .addCase(fetchForm.fulfilled, (state, action) => {
        state.form = action.payload;
        state.fetchStatus = FETCH_STATUS_TYPES_ENUM.SUCCEEDED;
        state.initialized = true;
      })
      .addCase(fetchForm.rejected, (state, action) => {
        state.fetchStatus = FETCH_STATUS_TYPES_ENUM.FAILED;
        state.fetchError = action.error.message || 'Failed to fetch form';
      })
      .addCase(saveForm.pending, (state) => {
        state.saveStatus = FETCH_STATUS_TYPES_ENUM.LOADING;
      })
      .addCase(saveForm.fulfilled, (state, action) => {
        state.form = action.payload;
        state.saveStatus = FETCH_STATUS_TYPES_ENUM.SUCCEEDED;
        state.hasChanged = false;
      })
      .addCase(saveForm.rejected, (state, action) => {
        state.saveStatus = FETCH_STATUS_TYPES_ENUM.FAILED;
        state.saveError = action.error.message || 'Failed to save form';
      })
      .addCase(markFormSubmissionAsSeen.fulfilled, (state) => {
        state.form.newResponses = state.form.newResponses - 1;
      })

      // External actions
      .addCase(updateForm.fulfilled, (state, action) => {
        state.form = action.payload;
        state.hasChanged = false;
      })
      .addCase(updateFormClients.fulfilled, (state, action) => {
        state.form = {
          ...state.form,
          clientIds: action.payload.clientIds,
          clients: action.payload.clients,
        };
      });
  },
});

export const {
  updatePage,
  setForm,
  reset,
  updateTitle,
  updateDescription,
  updateRecurring,
  updateRecurringFrequency,
  updateRecurringUnit,
  updateRecurringStartDate,
  updateShareWith,
  addField,
  duplicateField,
  deleteField,
  updateField,
  reorderFields,
  updateQuestion,
  toggleRequired,
  addUpdateOptions,
  updateMaxRating,
  changeFieldType,
  addUpdateClients,
} = slice.actions;

export default slice.reducer;
