import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useUnsavedPrompt } from './useUnsavedPrompt';
import { useDispatch, useSelector } from 'src/redux/store';
import useHeaderComponent from './useHeaderComponent';
import { FETCH_STATUS_TYPES_ENUM, PROGRAM_PAGE_ENUM } from 'src/@types/enums';
import { fetchProgram, getProgram, programResetAction } from 'src/redux/slices/program/program';
import { fetchProgramWeeks } from 'src/redux/slices/program/programWeeks';
import { programHasChanges } from 'src/redux/slices/program/programChangeTracker';
import { ProgramHeaderOptions } from 'src/sections/@dashboard/program';

// ----------------------------------------------------------------------

type ProgramProps = {
  template?: boolean;
};

export default function useProgram({ template = false }: ProgramProps) {
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(PROGRAM_PAGE_ENUM.PROGRAM);

  // useLeavePageConfirm(true, 'You have unsaved changes. Are you sure want to leave this page?');

  const { setHeaderComponent, setHeaderOptions } = useHeaderComponent();

  const { id } = useParams();

  // const id = 'b96d2fe9-30fb-40fd-91e1-5190a487f5f9';
  const currentProgram = useSelector(getProgram);
  const unsavedChanges = useSelector(programHasChanges);

  useUnsavedPrompt(unsavedChanges);

  const { id: programId, title, imageUrl, status } = currentProgram;

  const handlePageChange = useCallback(
    (event: React.SyntheticEvent<Element, Event>, newPage: PROGRAM_PAGE_ENUM) => {
      setCurrentPage(newPage);
    },
    [setCurrentPage]
  );

  useEffect(() => {
    if (id && status === FETCH_STATUS_TYPES_ENUM.IDLE) {
      dispatch(
        fetchProgram({
          programId: id as string,
          template,
        })
      ).then(() => {
        dispatch(
          fetchProgramWeeks({
            programId: id as string,
            template,
          })
        );
      });
    }
  }, [dispatch, id, status, template]);

  // If the url id changes, reset everything to fetch the new program
  useEffect(() => {
    if (id && programId && id !== programId) {
      dispatch(programResetAction());
    }
  }, [dispatch, id]);

  // Clean up when screen unmounts
  useEffect(() => {
    const unsubscribe = () => {
      dispatch(programResetAction());
    };
    return unsubscribe;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (programId && status === FETCH_STATUS_TYPES_ENUM.SUCCEEDED) {
      // setHeaderComponent(
      //   <ProgramHeaderComponent
      //     id={programId}
      //     title={title}
      //     imageUrl={imageUrl}
      //     currentPage={currentPage}
      //     fetchStatus={status}
      //     handlePageChange={handlePageChange}
      //   />
      // );
      setHeaderOptions(<ProgramHeaderOptions />);
    }
  }, [
    programId,
    status,
    title,
    imageUrl,
    currentPage,
    handlePageChange,
    setHeaderComponent,
    setHeaderOptions,
  ]);

  return {
    currentPage,
    currentProgram,
    id,
    title,
    imageUrl,
    fetchStatus: status,
    unsavedChanges,
  };
}
