// // @mui
// import { styled } from '@mui/material/styles';
// import { Box, Link, Typography, Avatar } from '@mui/material';
// // Redux
// import { useSelector } from 'src/redux/store';
// import { getUser } from 'src/redux/slices/user';

// // ----------------------------------------------------------------------

// const RootStyle = styled('div')(({ theme }) => ({
//   display: 'flex',
//   alignItems: 'center',
//   padding: theme.spacing(2, 2.5),
//   borderRadius: Number(theme.shape.borderRadius) * 1.5,
//   backgroundColor: theme.palette.grey[500_12],
//   transition: theme.transitions.create('opacity', {
//     duration: theme.transitions.duration.shorter,
//   }),
// }));

// // ----------------------------------------------------------------------

// type Props = {
//   isCollapse: boolean | undefined;
// };

// export default function NavbarAccount({ isCollapse }: Props) {
//   const user = useSelector(getUser);
//   const { profilePictureUrl, firstName, lastName } = user;
//   const name = firstName + ' ' + lastName;

//   /*
//   Level 1: 0-3 clients - Starter [grey]
// Level 2: 4-10 clients - Pro [green]
// Level 3: 11-20 clients - Advanced [blue]
// Level 4: 21-30 clients - Elite [purple]
// Level 5: 31+ clients - Legendary [golden]
// */
//   const level = 'Starter';

//   return (
//     <Link underline="none" color="inherit">
//       <RootStyle
//         sx={{
//           ...(isCollapse && {
//             bgcolor: 'transparent',
//           }),
//         }}
//       >
//         <Avatar src={profilePictureUrl} alt={name} />

//         <Box
//           sx={{
//             ml: 2,
//             transition: (theme) =>
//               theme.transitions.create('width', {
//                 duration: theme.transitions.duration.shorter,
//               }),
//             ...(isCollapse && {
//               ml: 0,
//               width: 0,
//             }),
//           }}
//         >
//           <Typography variant="subtitle2" noWrap>
//             {name}
//           </Typography>
//           <Typography variant="body2" noWrap sx={{ color: 'text.secondary' }}>
//             Coach
//           </Typography>
//         </Box>
//       </RootStyle>
//     </Link>
//   );
// }

import { Link as RouterLink } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Box, Link, Typography, Avatar, Badge, LinearProgress } from '@mui/material';
// Redux
import { useSelector } from 'src/redux/store';
import { getUser } from 'src/redux/slices/user';
import { PATH_DASHBOARD } from 'src/routes/paths';
// import StarIcon from '@mui/icons-material/Star'; // Example icon for badge

// ----------------------------------------------------------------------

// styled('div')(({ theme }) => ({
const RootStyle = styled('div')(
  ({
    theme,
    level,
  }: {
    theme?: any;
    level: 'Starter' | 'Pro' | 'Advanced' | 'Elite' | 'Legendary';
  }) => {
    const getBackground = () => {
      switch (level) {
        case 'Legendary':
          return '#b58b00'; // Deep, rich gold for a Legendaryary vibe
        case 'Elite':
          return '#6b84b4'; // Darkened steel blue with a premium feel
        case 'Advanced':
          return '#2b5b99'; // Darker royal blue for advanced level
        case 'Pro':
          return '#5a8236'; // Darker, muted green representing growth and proficiency
        case 'Starter':
        default:
          return '#8a96a2'; // Muted grey-blue for a polished beginner level
      }
    };

    return {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(2, 2.5),
      borderRadius: Number(theme.shape.borderRadius) * 1.5,
      // background: getBackground(),
      backgroundColor: getBackground(),
      boxShadow: `0 0 0px ${level === 'Legendary' ? 'gold' : 'none'}`,
      transition: theme.transitions.create(['opacity', 'box-shadow'], {
        duration: theme.transitions.duration.shorter,
      }),
      '&:hover': {
        boxShadow: `0 0 20px ${level === 'Legendary' ? 'gold' : 'white'}`,
      },
    };
  }
);

// ----------------------------------------------------------------------

const getBorderColor = (level: 'Starter' | 'Pro' | 'Advanced' | 'Elite' | 'Legendary') => {
  switch (level) {
    case 'Legendary':
      return '#d4af37'; // Gold tone for a premium feel, complementing the background
    case 'Elite':
      return '#8da1c7'; // Deep purple, matching the prestigious elite vibe
    case 'Advanced':
      return '#2b5d9f'; // A medium-deep blue to match the advanced level
    case 'Pro':
      return '#77a042'; // A strong, natural green complementing growth
    case 'Starter':
    default:
      return '#949ea8'; // A slightly darker neutral grey-blue for the starter level
  }
};

const getLevelEmoji = (level: 'Starter' | 'Pro' | 'Advanced' | 'Elite' | 'Legendary') => {
  switch (level) {
    case 'Legendary':
      return '🏆';
    case 'Elite':
      return '💎';
    case 'Advanced':
      return '🏅';
    case 'Pro':
      return '🥈';
    case 'Starter':
    default:
      return '';
  }
};

type Props = {
  isCollapse: boolean | undefined;
};

export default function NavbarAccount({ isCollapse }: Props) {
  const user = useSelector(getUser);
  const { activeClients, profilePictureUrl, firstName, lastName } = user; // Assume clientCount comes from user
  const name = `${firstName} ${lastName}`;

  const activeClientsCount = activeClients === undefined ? 0 : activeClients;

  // Determine level based on client count
  const getLevel = (clientCount: number) => {
    if (clientCount > 30) return 'Legendary';
    if (clientCount > 20) return 'Elite';
    if (clientCount > 10) return 'Advanced';
    if (clientCount > 3) return 'Pro';
    return 'Starter';
  };

  // Return target and remaining clients for next level
  const getProgressTowardsNextLevel = (clientCount: number) => {
    if (clientCount >= 20)
      return { target: 31, remaining: 31 - clientCount, nextLevel: 'Legendary' };
    if (clientCount >= 10) return { target: 21, remaining: 21 - clientCount, nextLevel: 'Elite' };
    if (clientCount >= 4) return { target: 11, remaining: 11 - clientCount, nextLevel: 'Advanced' };
    return { target: 4, remaining: 4 - clientCount, nextLevel: 'Pro' };
  };

  const level: 'Starter' | 'Pro' | 'Advanced' | 'Elite' | 'Legendary' =
    getLevel(activeClientsCount);
  const nextLevelProgress = getProgressTowardsNextLevel(activeClientsCount);

  return (
    <Link component={RouterLink} to={PATH_DASHBOARD.profile.root} underline="none" color="inherit">
      <RootStyle
        sx={{
          ...(isCollapse && {
            bgcolor: 'transparent',
            boxShadow: 'none',
          }),
        }}
        level={level} // Pass level for dynamic styling
      >
        <Badge
          // badgeContent={<StarIcon sx={{ color: level === 'Legendary' ? 'gold' : 'grey' }} />}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <Avatar
            src={profilePictureUrl}
            alt={name}
            sx={{ border: `2px solid ${getBorderColor(level)}` }}
          />
        </Badge>

        <Box
          sx={{
            ml: 2,
            transition: (theme) =>
              theme.transitions.create('width', {
                duration: theme.transitions.duration.shorter,
              }),
            ...(isCollapse && {
              ml: 0,
              width: 0,
              opacity: 0,
            }),
          }}
        >
          <Typography variant="subtitle2" noWrap sx={{ color: 'white' }}>
            {name}
          </Typography>
          <Typography variant="caption" noWrap sx={{ color: 'white' }}>
            {getLevelEmoji(level)} {level} Coach
          </Typography>
        </Box>
      </RootStyle>

      {/* Progress Bar Based on Clients until next level */}
      {/* Only show if activeClientsCount < 31 */}

      {activeClientsCount < 31 && (
        <>
          <LinearProgress
            variant="determinate"
            value={(activeClientsCount / nextLevelProgress.target) * 100}
            sx={{
              ...(isCollapse && {
                display: 'none',
              }),
              mt: 2,
              width: 'full',
              height: 10,
              borderRadius: 5,
              bgcolor: 'grey.500',
            }}
          />
          <Typography
            variant="caption"
            sx={{
              ...(isCollapse && {
                display: 'none',
              }),
              mt: 1,
              color: 'text.secondary',
            }}
          >
            +{nextLevelProgress.remaining} client{nextLevelProgress.remaining > 1 ? 's' : ''} until{' '}
            {nextLevelProgress.nextLevel}
          </Typography>
        </>
      )}
    </Link>
  );
}
