import { collection, getDocs, query } from 'firebase/firestore';
import { ProgramWeek_WithID, Workout_WithID } from 'src/@types/firebase';
import { DB } from 'src/contexts/FirebaseContext';
import convertFirebaseDataDates from 'src/utils/convertFirebaseDataDates';

type Props = {
  programId: string;
  weeksOnly?: boolean;
  isTemplate?: boolean;
};

/**
 * Gets all the programWeeks and workouts for a program
 *
 * @param programId - An id referencing to a `Firestore` program
 * @param weeksOnly - If true, only the programWeeks will be returned
 * @returns programWeeks - An array of program weeks
 * @returns workouts - An array of workouts
 */
const handleLoadProgramWeeks = async ({ programId, weeksOnly, isTemplate }: Props) => {
  const programWeeks: ProgramWeek_WithID[] = [];
  const workouts: Workout_WithID[] = [];

  const q = query(
    collection(DB, isTemplate ? 'programTemplates' : 'programs', programId, 'programWeeks')
  );
  const querySnapshot = await getDocs(q);

  querySnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
    const { id } = doc;
    const data = doc.data();
    const item = { ...data, id, programId } as ProgramWeek_WithID;
    programWeeks.push(item);
  });

  if (weeksOnly) {
    return { programWeeks };
  }

  // Get all workouts for this week
  for await (const programWeek of programWeeks) {
    const workoutQuery = query(
      collection(
        DB,
        isTemplate ? 'programTemplates' : 'programs',
        programId,
        'programWeeks',
        programWeek.id,
        'workouts'
      )
    );
    const workoutQuerySnapshot = await getDocs(workoutQuery);
    workoutQuerySnapshot.forEach((workoutDoc) => {
      const workoutId = workoutDoc.id;
      const workoutData = workoutDoc.data();

      if (!workoutData?.lastUpdated) {
        workoutData.lastUpdated = workoutData.dateCreated;
      }

      convertFirebaseDataDates(workoutData);

      const workout = {
        ...workoutData,
        id: workoutId,
        programWeekId: programWeek.id,
        programId,
      } as Workout_WithID;
      workouts.push(workout);
    });
  }

  return { programWeeks, workouts };
};

export default handleLoadProgramWeeks;
