import { AnyAction, combineReducers } from 'redux';
// import { persistReducer } from 'redux-persist';
import createWebStorage from 'redux-persist/lib/storage/createWebStorage';
// slices
// programs
import programsReducer from './slices/programs';
import programTemplatesReducer from './slices/programTemplates';
// program
import programReducer from './slices/program/program';
import programChangeTrackerReducer from './slices/program/programChangeTracker';
import programWeeksReducer from './slices/program/programWeeks';
import workoutsReducer from './slices/program/workouts';
import workoutExerciseGroupsReducer from './slices/program/workoutExerciseGroups';
import workoutExercisesReducer from './slices/program/workoutExercises';
import workoutExerciseMetricsReducer from './slices/program/workoutExerciseMetrics';
import workoutExerciseMetricValuesReducer from './slices/program/workoutExerciseMetricValues';
import workoutDragItemsReducer from './slices/program/workoutDragItems';
import collapsedItemsReducer from './slices/program/collapsedItems';
import exercisesToAddReducer from './slices/program/exercisesToAdd';
import exerciseModalReducer from './slices/program/exerciseModal';
import exerciseSwapModalReducer from './slices/program/exerciseSwapModal';
import programImageModalReducer from './slices/program/programImageModal';
import copyToModalReducer from './slices/program/copyToModal';
// coaches
import coachesReducer from './slices/coaches';
// clients
import clientsReducer from './slices/clients';
import clientProfileReducer from './slices/clientProfile';
// exercises
import exercisesReducer from './slices/exercises';
// exercise metrics
import exerciseMetricsReducer from './slices/exerciseMetrics';
// measurements
import measurementsReducer from './slices/measurements';
// user
import userReducer, { logoutAction } from './slices/user';
// movement tags
import movementTagsReducer from './slices/movementTags';
// muscle tags
import muscleTagsReducer from './slices/muscleTags';
// exercise graph data
import exerciseDataReducer from './slices/exerciseData/exerciseData';
import exerciseGraphOptionsReducer from './slices/exerciseData/exerciseGraphOptions';
// user history
import userHistoryReducer from './slices/userHistory';
// records
// import recordsReducer from './slices/records';
// workout log
import workoutLogReducer from './slices/workoutLog';
// measurement log data
import measurementLogDataReducer from './slices/measurementLogData/measurementLogData';
// stripe
import stripeInvoicesReducer from './slices/stripeInvoices';
// notifications
import notificationsReducer from './slices/notifications';
// habits
import habitsReducer from './slices/habits';
// user habits
import userHabitsReducer from './slices/userHabits';
// forms
import formBuilderReducer from './slices/formBuilder';
import formReducer from './slices/forms';
import formSubmissionsReducer from './slices/formSubmissions';
import formSubmissionReducer from './slices/formSubmission';

// @types
import {
  PROGRAM_WEEKS,
  WORKOUTS,
  WORKOUT_DRAG_ITEMS,
  WORKOUT_EXERCISES,
  WORKOUT_EXERCISE_GROUPS,
  WORKOUT_EXERCISE_METRICS,
  WORKOUT_EXERCISE_METRIC_VALUES,
} from './slices/program/constants/keys';

// ----------------------------------------------------------------------

const createNoopStorage = () => ({
  getItem(_key: string) {
    return Promise.resolve(null);
  },
  setItem(_key: string, value: any) {
    return Promise.resolve(value);
  },
  removeItem(_key: string) {
    return Promise.resolve();
  },
});

const storage = typeof window !== 'undefined' ? createWebStorage('local') : createNoopStorage();

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

// const productPersistConfig = {
//   key: 'product',
//   storage,
//   keyPrefix: 'redux-',
//   whitelist: ['sortBy', 'checkout'],
// };

const appReducer = combineReducers({
  programs: programsReducer,
  programTemplates: programTemplatesReducer,
  program: programReducer,
  programChangeTracker: programChangeTrackerReducer,
  [PROGRAM_WEEKS]: programWeeksReducer,
  [WORKOUTS]: workoutsReducer,
  [WORKOUT_EXERCISE_GROUPS]: workoutExerciseGroupsReducer,
  [WORKOUT_EXERCISES]: workoutExercisesReducer,
  [WORKOUT_EXERCISE_METRICS]: workoutExerciseMetricsReducer,
  [WORKOUT_EXERCISE_METRIC_VALUES]: workoutExerciseMetricValuesReducer,
  [WORKOUT_DRAG_ITEMS]: workoutDragItemsReducer,
  collapsedItems: collapsedItemsReducer,
  exercisesToAdd: exercisesToAddReducer,
  exerciseModal: exerciseModalReducer,
  exerciseSwapModal: exerciseSwapModalReducer,
  programImageModal: programImageModalReducer,
  copyToModal: copyToModalReducer,
  coaches: coachesReducer,
  clients: clientsReducer,
  clientProfile: clientProfileReducer,
  exercises: exercisesReducer,
  exerciseMetrics: exerciseMetricsReducer,
  measurements: measurementsReducer,
  user: userReducer,
  movementTags: movementTagsReducer,
  muscleTags: muscleTagsReducer,
  exerciseData: exerciseDataReducer,
  exerciseGraphOptions: exerciseGraphOptionsReducer,
  userHistory: userHistoryReducer,
  // records: recordsReducer,
  workoutLog: workoutLogReducer,
  measurementLogData: measurementLogDataReducer,
  stripeInvoices: stripeInvoicesReducer,
  notifications: notificationsReducer,
  habits: habitsReducer,
  userHabits: userHabitsReducer,
  // product: persistReducer(productPersistConfig, productReducer),
  formBuilder: formBuilderReducer,
  forms: formReducer,
  formSubmissions: formSubmissionsReducer,
  formSubmission: formSubmissionReducer,
});

// Enables reset to initial state on logout
// https://stackoverflow.com/a/63992547/4526326
const rootReducer = (state: ReturnType<typeof appReducer> | undefined, action: AnyAction) => {
  /* if you are using RTK, you can import your action and use it's type property instead of the literal definition of the action  */
  if (action.type === logoutAction.type) {
    return appReducer(undefined, { type: undefined });
  }

  return appReducer(state, action);
};

export { rootPersistConfig, rootReducer };
