import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'src/redux/store';
import { FETCH_STATUS_TYPES_ENUM } from 'src/@types/enums';
import { doc, getDoc, Timestamp } from 'firebase/firestore';
import { DB } from 'src/contexts/FirebaseContext';
import { FormSubmission } from 'src/@types/firebase';

const initialState: FormSubmission & {
  status: FETCH_STATUS_TYPES_ENUM;
  error: string | null;
} = {
  id: '',
  formId: '',
  formTitle: '',
  creatorId: '',
  clientId: '',
  client: {
    id: '',
    firstName: '',
    lastName: '',
    profilePictureUrl: '',
  },
  dateSubmitted: Timestamp.now(),
  answers: [],
  seenByCreator: false,
  status: FETCH_STATUS_TYPES_ENUM.IDLE,
  error: null,
};

// Fetch a single form submission
export const fetchFormSubmission = createAsyncThunk<FormSubmission, { formSubmissionId: string }>(
  'formSubmission/fetchFormSubmission',
  async ({ formSubmissionId }) => {
    const docRef = doc(DB, 'formSubmissions', formSubmissionId);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      return { id: docSnap.id, ...docSnap.data() } as FormSubmission;
    } else {
      throw new Error('No such form submission! ' + formSubmissionId);
    }
  }
);

export const formSubmissionSlice = createSlice({
  name: 'formSubmission',
  initialState,
  reducers: {
    reset: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchFormSubmission.pending, (state) => {
        state.status = FETCH_STATUS_TYPES_ENUM.LOADING;
        state.error = null;
      })
      .addCase(fetchFormSubmission.fulfilled, (state, action) => {
        state.status = FETCH_STATUS_TYPES_ENUM.SUCCEEDED;
        Object.assign(state, action.payload);
      })
      .addCase(fetchFormSubmission.rejected, (state, action) => {
        state.status = FETCH_STATUS_TYPES_ENUM.FAILED;
        state.error = action.error.message || 'Failed to fetch form submission';
      });
  },
});

export const { reset } = formSubmissionSlice.actions;

export const selectFormSubmission = (state: RootState) => state.formSubmission;

export default formSubmissionSlice.reducer;
