import { createEntityAdapter, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ProgramFilter as ProgramFilterType } from 'src/@types/program';
import { FETCH_STATUS_TYPES_ENUM } from 'src/@types/enums';
import { RootState } from '../store';
import {
  collection,
  query,
  where,
  // orderBy,
  limit,
  startAt as firestoreStartAt,
  getDocs,
  QueryConstraint,
  orderBy,
  doc,
  writeBatch,
} from 'firebase/firestore';
import { DB } from 'src/contexts/FirebaseContext';
import useTypesense from 'src/hooks/useTypesense';
import { SearchParams } from 'typesense/lib/Typesense/Documents';
import { ProgramCleanable } from 'src/@types/typesense';
import convertTypesenseDataDates from 'src/utils/convertTypesenseDataDates';
import { closeCopyToModalAction } from './program/copyToModal';
import { ProgramTemplate_WithID } from 'src/@types/firebase';
import handleConvertProgramToProgramTemplate from './program/functions/duplicate/handleConvertProgramToProgramTemplate';
import handleDuplicateProgramTemplate from './program/functions/duplicate/handleDuplicateProgramTemplate';
import { handleLoadProgramWeeks } from './program/functions/load';
import handleConvertProgramTemplateToProgram from './program/functions/duplicate/handleConvertProgramTemplateToProgram';
import { saveProgram, saveProgramDetails } from './program/program';

const FETCH_LIMIT = 30;

export const fetchProgramTemplates = createAsyncThunk<{
  programTemplates: ProgramTemplate_WithID[];
  lastVisibleDoc: any;
  page: number;
}>('programTemplates/fetchProgramTemplates', async (_, { getState }) => {
  const state = getState() as RootState;
  const userId = state.user.id;
  const { filters, sortBy } = state.programTemplates;
  let { lastVisibleDoc, page } = state.programTemplates;

  const creatorIds = [userId];

  const programTemplates: ProgramTemplate_WithID[] = [];

  if (!filters.searchPhrase) {
    let firebaseOrderBy = orderBy('title', 'asc');
    if (sortBy === 'titleDesc') {
      firebaseOrderBy = orderBy('title', 'desc');
    }

    const queryConstraints: QueryConstraint[] = [
      firebaseOrderBy,
      where('creatorIds', 'array-contains', userId),
      // firebaseOrderBy,
      limit(FETCH_LIMIT),
    ];

    if (lastVisibleDoc) {
      queryConstraints.push(firestoreStartAt(lastVisibleDoc));
    }

    const q = query(collection(DB, 'programTemplates'), ...queryConstraints);

    const querySnapshot = await getDocs(q);
    lastVisibleDoc = querySnapshot.docs[querySnapshot.docs.length - 1];
    querySnapshot.forEach((doc) => {
      const item = { ...doc.data(), id: doc.id } as ProgramTemplate_WithID;
      programTemplates.push(item);
    });
  } else {
    // increment page
    page += 1;

    const searchParameters: SearchParams = {
      q: filters.searchPhrase ? filters.searchPhrase : '*',
      query_by: 'title, description',
      query_by_weights: '4, 2',
      page: page,
      per_page: FETCH_LIMIT,
    };

    // Only search for templates
    const templateFilter = 'template:= true';
    let filterBy = templateFilter;

    // Creator filters
    if (creatorIds.length) {
      const creatorFilter = `creatorIds:= [${creatorIds.join()}]`;
      filterBy = filterBy ? filterBy + ' && ' + creatorFilter : creatorFilter;
    }

    if (filterBy) {
      searchParameters.filter_by = filterBy;
    }

    // Typesense search
    const client = useTypesense();

    const response = await client
      .collections<ProgramCleanable>('programTemplates')
      .documents()
      .search(searchParameters);

    if (response?.hits && response.hits.length !== 0) {
      programTemplates.push(
        ...response.hits.map((hit) => {
          const item = hit.document;
          // Remove Typesense only fields re: ExerciseTypesense
          delete item.dateCreatedUnix;
          delete item.lastUpdatedUnix;
          convertTypesenseDataDates(item);

          const programTemplate: ProgramTemplate_WithID = {
            ...item,
            id: hit.document.id,
          };

          return programTemplate;
        })
      );
    }
  }

  return { programTemplates, lastVisibleDoc, page };
});

export const convertProgramToProgramTemplate = createAsyncThunk<ProgramTemplate_WithID, string>(
  'programTemplates/convertProgramToProgramTemplate',
  async (programId) => {
    const item = await handleConvertProgramToProgramTemplate({ programId });
    return item;
  }
);

export const duplicateProgramTemplate = createAsyncThunk<ProgramTemplate_WithID, string>(
  'programTemplates/duplicateProgramTemplate',
  async (programTemplateId) => {
    const item = await handleDuplicateProgramTemplate({ programTemplateId });
    return item;
  }
);

export const convertProgramTemplateToProgram = createAsyncThunk<string, string>(
  'programTemplates/convertProgramTemplateToProgram',
  async (programTemplateId) => {
    const item = await handleConvertProgramTemplateToProgram({ programTemplateId });
    return item.id;
  }
);

// Delete a program and all associated data
export const deleteProgramTemplate = createAsyncThunk<
  string,
  {
    programTemplateId: string;
  }
>('programTemplates/deleteProgramTemplate', async ({ programTemplateId }) => {
  const batch = writeBatch(DB);

  // Delete all subcollections (programWeeks, workouts)
  // Get all programWeeks and workouts
  const { programWeeks, workouts } = await handleLoadProgramWeeks({
    programId: programTemplateId,
    isTemplate: true,
  });

  if (!programWeeks || !workouts) {
    throw new Error('Program weeks or workouts are undefined');
  }

  // Delete program in firestore
  const programRef = doc(DB, 'programTemplates', programTemplateId);

  batch.delete(programRef);

  // Delete programWeeks
  programWeeks.forEach((programWeek) => {
    const programWeekRef = doc(
      DB,
      'programTemplates',
      programTemplateId,
      'programWeeks',
      programWeek.id
    );
    batch.delete(programWeekRef);
  });

  // Delete workouts
  workouts.forEach((workout) => {
    const workoutRef = doc(
      DB,
      'programTemplates',
      programTemplateId,
      'programWeeks',
      workout.programWeekId,
      'workouts',
      workout.id
    );
    batch.delete(workoutRef);
  });

  await batch.commit();

  return programTemplateId;
});

const programTemplatesAdapter = createEntityAdapter<ProgramTemplate_WithID>({
  // Sort by title
  // sortComparer: (a: Program, b: Program) => a.title.localeCompare(b.title),
});

const initialState = programTemplatesAdapter.getInitialState({
  status: FETCH_STATUS_TYPES_ENUM.IDLE,
  error: null,
  page: 0,
  lastVisibleDoc: null,
  sortBy: 'titleAsc',
  filters: {
    searchPhrase: '',
  },
} as { status: FETCH_STATUS_TYPES_ENUM; error: string | null; page: number; lastVisibleDoc: any | null; sortBy: string | null; filters: ProgramFilterType });

export const slice = createSlice({
  name: 'programTemplates',
  initialState,
  reducers: {
    reset: () => initialState,
    //  SORT & FILTER PRODUCTS
    programsSortBy(state, action) {
      state.sortBy = action.payload;
    },
    //  SORT & FILTER PRODUCTS
    startSearch: (state) => {
      programTemplatesAdapter.removeAll(state);
      state.page = initialState.page;
      state.lastVisibleDoc = initialState.lastVisibleDoc;
      state.status = FETCH_STATUS_TYPES_ENUM.SEARCHING;
      state.error = null;
    },
    endSearch: (state, action) => {
      state.filters.searchPhrase = action.payload;
      state.status = FETCH_STATUS_TYPES_ENUM.IDLE;
    },
  },
  extraReducers(builder) {
    builder
      // Reset
      .addCase(closeCopyToModalAction, () => initialState)

      // Internal
      .addCase(fetchProgramTemplates.pending, (state) => {
        state.status = FETCH_STATUS_TYPES_ENUM.LOADING;
      })
      .addCase(fetchProgramTemplates.fulfilled, (state, action) => {
        // state.status = 'succeeded';
        // Upsert all the added exercise metrics
        const { lastVisibleDoc, page, programTemplates: items } = action.payload;

        if (items.length !== 0) {
          programTemplatesAdapter.upsertMany(state, items);
          state.lastVisibleDoc = lastVisibleDoc;
          state.page = page;
          // Change status to succeeded
          if (items.length < FETCH_LIMIT) {
            state.status = FETCH_STATUS_TYPES_ENUM.COMPLETED;
          } else {
            state.status = FETCH_STATUS_TYPES_ENUM.SUCCEEDED;
          }
        } else {
          state.status = FETCH_STATUS_TYPES_ENUM.COMPLETED;
        }
      })
      .addCase(fetchProgramTemplates.rejected, (state, action) => {
        state.status = FETCH_STATUS_TYPES_ENUM.FAILED;
        state.error = action?.error?.message ? action.error.message : null;
        console.error(action?.error);
      })

      // Duplicate Program Template
      .addCase(duplicateProgramTemplate.fulfilled, (state, action) => {
        const programTemplate = action.payload;
        // Insert at the index of the item that was duplicated
        const programTemplates = Object.values(state.entities).filter(
          (pt): pt is ProgramTemplate_WithID => !!pt
        );
        const index = programTemplates.findIndex((item) => item.title === programTemplate.title);
        // Insert at the index of the item that was duplicated
        programTemplates.splice(index, 0, programTemplate);

        programTemplatesAdapter.setAll(state, programTemplates);
      })

      .addCase(convertProgramToProgramTemplate.fulfilled, (state, action) => {
        const programTemplate = action.payload;

        // Upsert the new program template
        programTemplatesAdapter.upsertOne(state, programTemplate);
      })

      // External
      .addCase(saveProgram.fulfilled, (state, action) => {
        const item = action.payload;

        // If not a template
        if (item?.template) {
          programTemplatesAdapter.upsertOne(state, item);
        }
      })
      .addCase(saveProgramDetails.fulfilled, (state, action) => {
        const item = action.payload;

        // If not a template
        if (item?.template) {
          programTemplatesAdapter.upsertOne(state, item);
        }
      })

      .addCase(deleteProgramTemplate.fulfilled, (state, action) => {
        const itemId = action.payload;
        programTemplatesAdapter.removeOne(state, itemId);
      });

    // Convert Program to Program Template
    // .addCase(convertProgramToProgramTemplate.fulfilled, (state, action) => {
    //   const programTemplate = action.payload;
    //   // Insert at the index of the item that was duplicated
    //   const programTemplates = Object.values(state.entities).filter(
    //     (pt): pt is ProgramTemplate_WithID => !!pt
    //   );
    //   const index = programTemplates.findIndex((item) => item.title === programTemplate.title) || 0;
    //   // Insert at the index of the item that was duplicated
    //   programTemplates.splice(index, 0, programTemplate);

    //   programTemplatesAdapter.setAll(state, programTemplates);
    // });
  },
});

export const { reset, programsSortBy, startSearch, endSearch } = slice.actions;

export default slice.reducer;

// Export the customized selectors for this adapter using `getSelectors`
export const {
  selectAll: selectAllProgramTemplates,
  selectById: selectProgramTemplateById,
  // Pass in a selector that returns the posts slice of state
} = programTemplatesAdapter.getSelectors((state: RootState) => state.programTemplates);

export const getProgramTemplatesFetchStatus = (state: RootState) => state.programTemplates.status;
export const getProgramTemplatesFetchError = (state: RootState) => state.programTemplates.error;
export const getProgramTemplatesFilters = (state: RootState) => state.programTemplates.filters;
export const getProgramTemplatesSortBy = (state: RootState) => state.programTemplates.sortBy;
