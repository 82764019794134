export default function convertFirebaseDataDates(data: any) {
  if (data?.dateCreated?.toDate) {
    data.dateCreated = data.dateCreated.toDate();
  }
  if (data?.lastUpdated?.toDate) {
    data.lastUpdated = data.lastUpdated.toDate();
  }

  // Coach Clients
  if (data?.dateAdded?.toDate) {
    data.dateAdded = data.dateAdded.toDate();
  }
  if (data?.client?.dateCreated?.toDate) {
    data.client.dateCreated = data.client.dateCreated.toDate();
  }

  // Notifications
  if (data?.dateSeen?.toDate) {
    data.dateSeen = data.dateSeen.toDate();
  }

  // User Habit Logs
  if (data?.date?.toDate) {
    data.date = data.date.toDate();
  }

  // Last Active Date
  if (data?.lastActiveDate?.toDate) {
    data.lastActiveDate = data.lastActiveDate.toDate();
  }
}
