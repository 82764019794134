import { doc, writeBatch } from 'firebase/firestore';
import { ProgramSaveData } from 'src/@types/firebase';
import { DB } from 'src/contexts/FirebaseContext';

type Props = {
  programSaveData: ProgramSaveData;
  isTemplate?: boolean;
};

const prepareFirebaseQuery = ({ programSaveData, isTemplate }: Props) => {
  if (!programSaveData.program && !programSaveData.programWeeks && !programSaveData.workouts) {
    return null;
  }

  const batch = writeBatch(DB);

  const COLLECTION = isTemplate ? 'programTemplates' : 'programs';

  // Program
  const programToSave = programSaveData?.program?.save;
  if (programToSave) {
    const { id, ...rest } = programToSave;
    const ref = doc(DB, COLLECTION, id);
    batch.set(ref, rest);
  }

  // Program Weeks
  const programWeeksToSave = programSaveData?.programWeeks?.save;
  const programWeekSaveIds = programWeeksToSave ? Object.keys(programWeeksToSave) : [];
  if (programWeeksToSave && programWeekSaveIds.length > 0) {
    programWeekSaveIds.forEach((id) => {
      const item = programWeeksToSave[id];
      const { programId } = item;
      const ref = doc(DB, COLLECTION, programId, 'programWeeks', id);
      batch.set(ref, item);
    });
  }
  const programWeeksToRemove = programSaveData?.programWeeks?.remove;
  if (programWeeksToRemove && programWeeksToRemove.length > 0) {
    programWeeksToRemove.forEach((item) => {
      const { id, programId } = item;
      const ref = doc(DB, COLLECTION, programId, 'programWeeks', id);
      batch.delete(ref);
    });
  }

  // Workouts
  const workoutsToSave = programSaveData?.workouts?.save;

  const workoutSaveIds = workoutsToSave ? Object.keys(workoutsToSave) : [];
  if (workoutsToSave && workoutSaveIds.length > 0) {
    workoutSaveIds.forEach((id) => {
      const item = workoutsToSave[id];
      const { programWeekId, programId } = item;
      const ref = doc(DB, COLLECTION, programId, 'programWeeks', programWeekId, 'workouts', id);
      batch.set(ref, item);
    });
  }
  const workoutsToRemove = programSaveData?.workouts?.remove;
  if (workoutsToRemove && workoutsToRemove.length > 0) {
    workoutsToRemove.forEach((item) => {
      const { id, programWeekId, programId } = item;
      const ref = doc(DB, COLLECTION, programId, 'programWeeks', programWeekId, 'workouts', id);
      batch.delete(ref);
    });
  }

  return batch;
};

export default prepareFirebaseQuery;
