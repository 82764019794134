// components
import { USER_TYPE_ENUM } from 'src/@types/enums';
import Iconify from 'src/components/Iconify';
// import Label from 'src/components/Label';
// import SvgIconStyle from '../../../components/SvgIconStyle';

// ----------------------------------------------------------------------

// const getIcon = (name: string) => (
//   <SvgIconStyle src={`/icons/${name}.svg`} sx={{ width: 1, height: 1 }} />
// );

const ICONS = {
  clients: <Iconify icon={'fa6-solid:users'} sx={{ width: 1, height: 1 }} />,
  coaches: <Iconify icon={'mdi:account-lock-open'} sx={{ width: 1, height: 1 }} />,
  programs: <Iconify icon={'entypo:text-document-inverted'} sx={{ width: 1, height: 1 }} />,
  exercises: <Iconify icon={'icon-park-outline:dumbbell'} sx={{ width: 1, height: 1 }} />,
  habits: <Iconify icon={'mdi:list-box'} sx={{ width: 1, height: 1 }} />,
  mobileApp: <Iconify icon={'mdi:cellphone'} sx={{ width: 1, height: 1 }} />,
  featureRequests: <Iconify icon={'mdi:lightbulb-on'} sx={{ width: 1, height: 1 }} />,
  // clip board
  forms: <Iconify icon={'mdi:clipboard-list'} sx={{ width: 1, height: 1 }} />,
};

const navConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'Coaching',
    items: [
      {
        title: 'Clients',
        path: '/dashboard/clients',
        icon: ICONS.clients,
      },
      {
        title: 'Coach Lydia Sign Ups',
        path: '/dashboard/coach-lydia-sign-ups',
        icon: ICONS.coaches,
        access: [USER_TYPE_ENUM.COACH_LYDIA_ADMIN],
      },
      {
        title: 'Coaches',
        path: '/dashboard/coaches',
        icon: ICONS.coaches,
        access: [USER_TYPE_ENUM.ADMIN],
      },
      // { title: 'Programs', path: '/dashboard/programs', icon: ICONS.programs },
      {
        title: 'Programs',
        path: '/dashboard/programs',
        icon: ICONS.programs,
        children: [
          { title: 'Programs', path: '/dashboard/programs' },
          { title: 'Templates', path: '/dashboard/programs/templates' },
        ],
      },
      {
        title: 'Exercises',
        path: '/dashboard/exercises',
        icon: ICONS.exercises,
        children: [
          { title: 'All Exercises', path: '/dashboard/exercises/all' },
          { title: 'My Exercises', path: '/dashboard/exercises/my-exercises' },
          {
            title: 'Global Exercises',
            path: '/dashboard/exercises/global',
            access: [USER_TYPE_ENUM.ADMIN],
          },
        ],
      },
      {
        title: 'Forms',
        path: '/dashboard/forms',
        icon: ICONS.forms,
      },
      {
        title: 'Habits',
        path: '/dashboard/habits',
        icon: ICONS.habits,
      },
      {
        title: 'Custom Mobile App',
        path: '/dashboard/mobile-app',
        icon: ICONS.mobileApp,
      },
    ],
  },
  {
    subheader: 'Support',
    items: [
      {
        title: 'Requests',
        path: '/dashboard/requests',
        icon: ICONS.featureRequests,
      },
    ],
  },
];

export default navConfig;
