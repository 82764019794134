import { doc, updateDoc } from 'firebase/firestore';
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import { CustomFile } from 'src/components/upload';
import { DB, STORAGE } from 'src/contexts/FirebaseContext';

type Props = {
  programId: string;
  imageUrl?: string;
  file?: CustomFile;
  isTemplate?: boolean;
};

const handleSaveProgramImage = async ({ programId, imageUrl, file, isTemplate }: Props) => {
  // Upload image to firebase storage
  if (file !== undefined) {
    // If the image is a file
    const storageRef = ref(STORAGE, `programs/${programId}`);

    await uploadBytes(storageRef, file).then(async (snapshot) => {
      await getDownloadURL(snapshot.ref).then(async (downloadURL) => {
        const ref = doc(DB, isTemplate ? 'programTemplates' : 'programs', programId);
        // Update to firebase
        await updateDoc(ref, { imageUrl: downloadURL });
        imageUrl = downloadURL;
      });
    });
  } else if (imageUrl !== undefined) {
    // Update firestrore with the new image url
    const ref = doc(DB, isTemplate ? 'programTemplates' : 'programs', programId);
    // Update to firebase
    await updateDoc(ref, { imageUrl });
  }

  return imageUrl;
};

export default handleSaveProgramImage;
