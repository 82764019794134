import { updateDoc, doc, Timestamp } from 'firebase/firestore';
import { DB } from 'src/contexts/FirebaseContext';

const setUserLastActiveDate = async (userId: string, previousLastActiveDate: Date | undefined) => {
  // Only update the last active date if it has been more than 6 hours since the last update
  if (previousLastActiveDate) {
    const diff = new Date().getTime() - previousLastActiveDate.getTime();
    if (diff < 1000 * 60 * 60 * 6) {
      return;
    }
  }

  const ref = doc(DB, 'users', userId);

  await updateDoc(ref, {
    lastActiveDate: Timestamp.now(),
  });
};

export default setUserLastActiveDate;
