import { logEvent } from 'firebase/analytics';
import { addDoc, collection, doc, setDoc, updateDoc } from 'firebase/firestore';
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import { Program as ProgramFB, ProgramCreator, Program_WithID } from 'src/@types/firebase';
import { CustomFile } from 'src/components/upload';
import { ANALYTICS, DB, STORAGE } from 'src/contexts/FirebaseContext';
import { RootState } from 'src/redux/store';
import { ProgramFormValuesProps } from 'src/sections/@dashboard/program/ProgramNewEditForm';
import { handleCreateFirebaseWorkout, handleCreateWeek } from '../create';
import cleanProgramData from 'src/utils/cleanProgramData';

type Props = {
  data: ProgramFormValuesProps;
  isEdit?: boolean;
  state: RootState;
  isTemplate?: boolean;
};

const handleSaveProgramDetails = async ({ data, isEdit, state, isTemplate }: Props) => {
  // eslint-disable-next-line prefer-destructuring
  let program: Program_WithID = { ...state.program };

  // Clean program data, remove redux properties
  program = cleanProgramData(program);

  const programId = program.id;

  // If the image is a string
  let { imageUrl } = program;
  if (data.image) {
    if (data.image && typeof data.image === 'string') {
      imageUrl = data.image;
    }
  }

  // Save program details
  if (isEdit && programId) {
    const updatedProgram: Partial<Program_WithID> = {
      lastUpdated: new Date(),
      title: data.title,
      description: data.description,
      users: data.users,
      userIds: data.users.map((user) => user.id),
      imageUrl,
    };

    const ref = doc(DB, isTemplate ? 'programTemplates' : 'programs', programId);
    await updateDoc(ref, updatedProgram);
    program = { ...program, ...updatedProgram };
  } else {
    const creator: ProgramCreator = {
      id: state.user.id,
      name: `${state.user.firstName} ${state.user.lastName}`,
      imageUrl: state.user.profilePictureUrl,
    };

    const newProgram: ProgramFB = {
      dateCreated: new Date(),
      title: data.title,
      imageUrl: imageUrl,
      description: data.description,
      published: true,
      lastUpdated: new Date(),
      creators: [creator],
      creatorIds: [creator.id],
      users: data.users,
      userIds: data.users.map((user) => user.id),
    };

    if (isTemplate) {
      newProgram.template = true;
    }

    const docRef = await addDoc(
      collection(DB, isTemplate ? 'programTemplates' : 'programs'),
      newProgram
    );
    program = { ...program, ...newProgram, id: docRef.id };

    const newProgramWeek = handleCreateWeek({
      programId: program.id,
      index: 0,
      name: '',
    });
    const { id: weekId, ...week } = newProgramWeek;
    const weekRef = doc(
      DB,
      isTemplate ? 'programTemplates' : 'programs',
      newProgramWeek.programId,
      'programWeeks',
      weekId
    );
    setDoc(weekRef, week);

    const newWorkout = handleCreateFirebaseWorkout({
      index: 0,
      programId: program.id,
      programWeekId: weekId,
    });
    const { id: workoutId, ...workout } = newWorkout;
    const workoutRef = doc(
      DB,
      isTemplate ? 'programTemplates' : 'programs',
      newProgramWeek.programId,
      'programWeeks',
      weekId,
      'workouts',
      workoutId
    );
    setDoc(workoutRef, workout);

    // Create analytics for a new program
    logEvent(ANALYTICS, 'create_program', {
      program_id: program.id,
      program_title: program.title,
      user_id: state.user.id,
    });
  }

  // Upload image to firebase storage
  if (data.image) {
    if (typeof data.image !== 'string') {
      // If the image is a file
      const file = data.image as CustomFile;
      if (!isEdit || (file?.name && file.name !== program.imageUrl)) {
        const storageRef = ref(STORAGE, `programs/${program.id}`);

        await uploadBytes(storageRef, file).then(async (snapshot) => {
          await getDownloadURL(snapshot.ref).then(async (downloadURL) => {
            const ref = doc(DB, isTemplate ? 'programTemplates' : 'programs', program.id);
            // Update to firebase
            await updateDoc(ref, { imageUrl: downloadURL });
            // Update to local state
            program = { ...program, imageUrl: downloadURL };
          });
        });
      }
    }
  }
  // If is edit = FALSE or imageUrl is different from the one in state
  // Upload image to firebase storage
  // const imageBlob = data.image as CustomFile;
  // if (!isEdit || (imageBlob?.name && imageBlob.name !== program.imageUrl)) {
  //   const storageRef = ref(STORAGE, `programs/${program.id}`);

  //   await uploadBytes(storageRef, imageBlob).then(async (snapshot) => {
  //     await getDownloadURL(snapshot.ref).then(async (downloadURL) => {
  //       const ref = doc(DB, 'programs', program.id);
  //       // Update to firebase
  //       await updateDoc(ref, { imageUrl: downloadURL });
  //       // Update to local state
  //       program = { ...program, imageUrl: downloadURL };
  //     });
  //   });
  // }

  return program;
};

export default handleSaveProgramDetails;
