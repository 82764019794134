import { createEntityAdapter, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { FETCH_STATUS_TYPES_ENUM } from 'src/@types/enums';
import { RootState } from '../store';
import {
  collection,
  query,
  where,
  limit,
  startAt as firestoreStartAt,
  getDocs,
  QueryConstraint,
  orderBy,
  doc,
  writeBatch,
  increment,
} from 'firebase/firestore';
import { DB } from 'src/contexts/FirebaseContext';
import { FormSubmission } from 'src/@types/firebase';

const FETCH_LIMIT = 30;

// Fetch forms for the current user
export const fetchFormSubmissions = createAsyncThunk<{
  formSubmissions: FormSubmission[];
  lastVisibleDoc: any;
  page: number;
}>('formSubmissions/fetchFormSubmissions', async (_, { getState }) => {
  const state = getState() as RootState;
  const userId = state.user.id;
  const formId = state.formBuilder.form.id;

  if (!formId) {
    throw new Error('Form ID is required');
  }

  const {
    filters,
    // sortBy
  } = state.formSubmissions;
  let { lastVisibleDoc, page } = state.formSubmissions;

  const formSubmissions: FormSubmission[] = [];

  if (!filters.searchPhrase) {
    // newest first
    let firebaseOrderBy = orderBy('dateSubmitted', 'desc');
    // if (sortBy === 'titleDesc') {
    //   firebaseOrderBy = orderBy('title', 'desc');
    // }

    const queryConstraints: QueryConstraint[] = [
      firebaseOrderBy,
      where('creatorId', '==', userId),
      where('formId', '==', formId),
      limit(FETCH_LIMIT),
    ];

    if (lastVisibleDoc) {
      queryConstraints.push(firestoreStartAt(lastVisibleDoc));
    }

    const q = query(collection(DB, 'formSubmissions'), ...queryConstraints);

    const querySnapshot = await getDocs(q);
    lastVisibleDoc = querySnapshot.docs[querySnapshot.docs.length - 1];
    querySnapshot.forEach((doc) => {
      const item = { ...doc.data(), id: doc.id } as FormSubmission;
      formSubmissions.push(item);
    });
  }

  return { formSubmissions, lastVisibleDoc, page };
});

// Mark a form as seen
export const markFormSubmissionAsSeen = createAsyncThunk<
  FormSubmission,
  { formSubmissionId: string }
>('formSubmissions/markFormSubmissionAsSeen', async ({ formSubmissionId }, { getState }) => {
  const state = getState() as RootState;
  const formId = state.formBuilder.form.id;
  const formSubmission = selectFormSubmissionById(state, formSubmissionId);

  if (!formId) {
    throw new Error('Form ID is required');
  }

  if (!formSubmission) {
    throw new Error('Form submission not found');
  }

  // Prevent marking the form as seen multiple times
  if (formSubmission?.seenByCreator === true) {
    return formSubmission;
  }

  const batch = writeBatch(DB);

  const updateFormSubmission: FormSubmission = {
    ...formSubmission,
    seenByCreator: true,
  };

  batch.update(doc(DB, 'formSubmissions', formSubmissionId), updateFormSubmission);

  // Decrement the form's newResponses count
  const formRef = doc(DB, 'forms', formId);
  batch.update(formRef, {
    newResponses: increment(-1),
  });

  await batch.commit();

  return updateFormSubmission;
});

// Delete a form
// export const deleteForm = createAsyncThunk<string, { formId: string }>(
//   'forms/deleteForm',
//   async ({ formId }) => {
//     const formRef = doc(DB, 'forms', formId);
//     await updateDoc(formRef, { deleted: true }); // Logical deletion
//     return formId;
//   }
// );

// Update form details
// export const updateForm = createAsyncThunk<Form, { formId: string; updates: Partial<Form> }>(
//   'forms/updateForm',
//   async ({ formId, updates }) => {
//     const formRef = doc(DB, 'forms', formId);
//     await updateDoc(formRef, updates);
//     return { id: formId, ...updates } as Form;
//   }
// );

const formSubmissionsAdapter = createEntityAdapter<FormSubmission>();

const initialState = formSubmissionsAdapter.getInitialState({
  status: FETCH_STATUS_TYPES_ENUM.IDLE,
  error: null,
  page: 0,
  lastVisibleDoc: null,
  sortBy: 'titleAsc',
  filters: {
    searchPhrase: '',
  },
} as { status: FETCH_STATUS_TYPES_ENUM; error: string | null; page: number; lastVisibleDoc: any | null; sortBy: string | null; filters: { searchPhrase: string } });

export const slice = createSlice({
  name: 'formSubmissions',
  initialState,
  reducers: {
    reset: () => initialState,
    sortFormsBy(state, action) {
      state.sortBy = action.payload;
    },
    startSearch: (state) => {
      formSubmissionsAdapter.removeAll(state);
      state.page = initialState.page;
      state.lastVisibleDoc = initialState.lastVisibleDoc;
      state.status = FETCH_STATUS_TYPES_ENUM.SEARCHING;
      state.error = null;
    },
    endSearch: (state, action) => {
      state.filters.searchPhrase = action.payload;
      state.status = FETCH_STATUS_TYPES_ENUM.IDLE;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchFormSubmissions.pending, (state) => {
        state.status = FETCH_STATUS_TYPES_ENUM.LOADING;
      })
      .addCase(fetchFormSubmissions.fulfilled, (state, action) => {
        const { lastVisibleDoc, page, formSubmissions: items } = action.payload;

        if (items.length !== 0) {
          formSubmissionsAdapter.upsertMany(state, items);
          state.lastVisibleDoc = lastVisibleDoc;
          state.page = page;
          if (items.length < FETCH_LIMIT) {
            state.status = FETCH_STATUS_TYPES_ENUM.COMPLETED;
          } else {
            state.status = FETCH_STATUS_TYPES_ENUM.SUCCEEDED;
          }
        } else {
          state.status = FETCH_STATUS_TYPES_ENUM.COMPLETED;
        }
      })
      .addCase(fetchFormSubmissions.rejected, (state, action) => {
        state.status = FETCH_STATUS_TYPES_ENUM.FAILED;
        state.error = action?.error?.message || null;
        console.error(action?.error);
      })

      .addCase(markFormSubmissionAsSeen.fulfilled, (state, action) => {
        const updatedForm = action.payload;
        formSubmissionsAdapter.upsertOne(state, updatedForm);
      });
    // .addCase(deleteForm.fulfilled, (state, action) => {
    //   const formId = action.payload;
    //   formsAdapter.removeOne(state, formId);
    // })
    // .addCase(updateForm.fulfilled, (state, action) => {
    //   const updatedForm = action.payload;
    //   formsAdapter.upsertOne(state, updatedForm);
    // })

    // External actions
    // .addCase(saveForm.fulfilled, (state, action) => {
    //   const form = action.payload;
    //   formsAdapter.upsertOne(state, form);
    // });
  },
});

export const { reset, sortFormsBy, startSearch, endSearch } = slice.actions;
export default slice.reducer;

export const {
  selectAll: selectAllFormSubmissions,
  selectById: selectFormSubmissionById,
  // Pass in a selector that returns the posts slice of state
} = formSubmissionsAdapter.getSelectors<RootState>((state) => state.formSubmissions);

export const getFormSubmissionsFetchStatus = (state: RootState) => state.formSubmissions.status;
export const getFormSubmissionsFetchError = (state: RootState) => state.formSubmissions.error;
export const getFormSubmissionsFilters = (state: RootState) => state.formSubmissions.filters;
export const getFormSubmissionsSortBy = (state: RootState) => state.formSubmissions.sortBy;
