import { useCallback } from 'react';
// @mui
import { alpha, styled, useTheme } from '@mui/material/styles';
import { TextField, Stack, Button, Tooltip } from '@mui/material';
// components
import WeekTabs from './WeekTabs';
import { ProgramUsers } from '../programs';
// import Scrollbar from 'src/components/Scrollbar';
// utils
import cssStyles from 'src/utils/cssStyles';
// types
import { Program } from 'src/@types/program_redux';
// import { ProgramCreator, ProgramUser } from 'src/@types/firebase';
// redux
import { useDispatch, useSelector } from 'src/redux/store';
import { updateProgramAction } from 'src/redux/slices/program/program';
import ProgramMenu from './ProgramMenu';
import Iconify from 'src/components/Iconify';
import { convertProgramTemplateToProgram } from 'src/redux/slices/programTemplates';
import { useNavigate } from 'react-router';
import { PATH_DASHBOARD } from 'src/routes/paths';
import { selectAllProgramChangeTackerItems } from 'src/redux/slices/program/programChangeTracker';
import { useSnackbar } from 'notistack';

// ----------------------------------------------------------------------

type RootProps = {
  imageurl: string; // Must be lowercase to prevent React error
};

const RootStyle = styled('div')<RootProps>(({ theme, imageurl }) => ({
  ...cssStyles(theme).bgImage({
    url: imageurl ? imageurl : '/static/placeholder/placeholder_program.jpg',
    startColor:
      theme.palette.mode === 'light'
        ? alpha(theme.palette.background.default, 0.6)
        : alpha(theme.palette.grey[900], 0.4),
    endColor:
      theme.palette.mode === 'light'
        ? alpha(theme.palette.grey[100], 0.9)
        : alpha(theme.palette.grey[900], 0.9),
  }),
  marginBottom: theme.spacing(4),
}));

// ----------------------------------------------------------------------

const StyledTextField = styled(TextField)(({ theme }) => ({
  '.MuiInput-underline:before': {
    borderBottom: `0px solid ${theme.palette.text.secondary}`,
  },
  '&& .MuiInput-underline:hover:before': {
    borderBottom: `2px solid ${theme.palette.text.secondary}`,
  },
}));

// ----------------------------------------------------------------------

type Props = {
  program: Program;
};

export default function ProgramHero({ program }: Props) {
  const dispatch = useDispatch();
  const theme = useTheme();
  const navigate = useNavigate();
  const programChanges = useSelector(selectAllProgramChangeTackerItems);
  const { enqueueSnackbar } = useSnackbar();

  const handleTitleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      dispatch(updateProgramAction({ id: program.id, updates: { title: e.target.value } }));
    },
    [dispatch, program.id]
  );

  const handleDescriptionChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      dispatch(updateProgramAction({ id: program.id, updates: { description: e.target.value } }));
    },
    [dispatch, program.id]
  );

  const handleUseTemplate = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    e.preventDefault();

    // Prevent navigation if there are unsaved changes
    if (programChanges.length > 0) {
      enqueueSnackbar('Please save your changes before using this template.', {
        variant: 'warning',
      });
      return;
    }

    // Use
    const newId = (await (
      await dispatch(convertProgramTemplateToProgram(program.id))
    ).payload) as string;
    navigate(PATH_DASHBOARD.program.root(newId));
  };

  return (
    <RootStyle imageurl={program.imageUrl}>
      <Stack
        sx={{
          zIndex: 9,
          py: 4,
          pb: 1,
          height: 325,
        }}
      >
        <Stack
          flex={1}
          direction="row"
          alignItems="flex-start"
          justifyContent="space-between"
          sx={{ px: 5 }}
        >
          <Stack flex={1} spacing={1}>
            <StyledTextField
              variant="standard"
              value={program.title}
              placeholder="Program Title"
              inputProps={{
                style: {
                  fontSize: theme.typography.h1.fontSize,
                  fontWeight: theme.typography.h1.fontWeight,
                  lineHeight: 1.5,
                },
              }}
              onChange={handleTitleChange}
              sx={{
                width: {
                  xs: '100%',
                  sm: '100%',
                  md: '100%',
                  lg: '50%',
                },
              }}
              // sx={{ input: { color: 'common.white' } }}
            />
            <StyledTextField
              variant="standard"
              value={program.description}
              multiline
              maxRows={3}
              placeholder="What are the goals of this program?"
              onChange={handleDescriptionChange}
              inputProps={{
                style: {
                  fontSize: theme.typography.body2.fontSize,
                  fontWeight: theme.typography.body2.fontWeight,
                  lineHeight: 1.5,
                  // color: 'white',
                },
              }}
              sx={{
                width: {
                  xs: '100%',
                  sm: '100%',
                  md: '100%',
                  lg: '50%',
                },
              }}
              // sx={{ input: { color: 'white' } }}
            />
          </Stack>
          <ProgramMenu programId={program.id} />
        </Stack>

        {program?.template ? (
          <Stack direction="row" alignItems="center" sx={{ ml: 5, mb: 2 }}>
            <Tooltip
              title="This is a template. Templates cannot be directly assigned to users."
              arrow
            >
              <Button
                variant="contained"
                color="warning"
                startIcon={<Iconify icon="eva:alert-triangle-fill" width={24} height={24} />}
              >
                Program Template
              </Button>
            </Tooltip>
            <Tooltip title="Use this template to create a new, assignable program." arrow>
              <Button
                onClick={handleUseTemplate}
                variant="contained"
                color="primary"
                startIcon={<Iconify icon="eva:checkmark-circle-fill" width={24} height={24} />}
                sx={{ ml: 1 }}
              >
                Use Template
              </Button>
            </Tooltip>
          </Stack>
        ) : (
          <Stack spacing={0.5} sx={{ px: 5, mb: 3 }}>
            {/* <ProgramPeopleChips people={program.creators} /> */}
            <ProgramUsers programId={program.id} programUsers={program.users} />
          </Stack>
        )}

        <WeekTabs />
      </Stack>
    </RootStyle>
  );
}

// ----------------------------------------------------------------------

// type ProgramPeopleChipsProps = {
//   people: (ProgramCreator | ProgramUser)[];
// };

// function ProgramPeopleChips({ people }: ProgramPeopleChipsProps) {
//   return (
//     <Stack>
//       <Typography variant="caption" color={'text.disabled'}>
//         Proudly built by:
//       </Typography>
//       <Scrollbar>
//         <Stack direction="row" spacing={1} sx={{ color: 'common.white' }}>
//           {people.map((person) => (
//             <Chip
//               key={person.id}
//               avatar={<Avatar alt={person.name} src={person.imageUrl} />}
//               label={person.name}
//               variant="outlined"
//               sx={{ color: 'common.white', fontSize: '0.75rem' }}
//             />
//           ))}
//         </Stack>
//       </Scrollbar>
//     </Stack>
//   );
// }

// ----------------------------------------------------------------------
