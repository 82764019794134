import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Dialog,
  IconButton,
  Slide,
  Stack,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { TransitionProps } from '@mui/material/transitions';
import {
  createContext,
  useState,
  useCallback,
  forwardRef,
  ReactElement,
  Ref,
  ReactNode,
  useEffect,
} from 'react';
import { ProgramUser } from 'src/@types/firebase';
import useClients from 'src/hooks/useClients';
import { assignProgramUsers, selectProgramById } from 'src/redux/slices/programs';
import { RootState, useDispatch, useSelector } from 'src/redux/store';
import Iconify from 'src/components/Iconify';

// ----------------------------------------------------------------------

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: ReactElement;
  },
  ref: Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

// ----------------------------------------------------------------------

export type ProgramClientContextProps = {
  openProgramClientModal: (programId: string) => Promise<void>;
  closeProgramClientModal: () => void;
};

const initialState: ProgramClientContextProps = {
  openProgramClientModal: async () => {},
  closeProgramClientModal: () => {},
};

const ProgramClientContext = createContext(initialState);

type ProgramClientProviderProps = {
  children: ReactNode;
};

function ProgramClientProvider({ children }: ProgramClientProviderProps) {
  const dispatch = useDispatch();
  const theme = useTheme();

  const { clientsList: clients } = useClients();

  const [programId, setProgramId] = useState<string | null>(null);
  const program = useSelector((state: RootState) =>
    selectProgramById(state, programId ? programId : '')
  );

  const [visible, setVisible] = useState<boolean>(false);
  const [selectedClients, setSelectedClients] = useState<ProgramUser[]>(program?.users || []);
  const [saving, setSaving] = useState<boolean>(false);

  const handleOpen = useCallback(async (newProgramId: string) => {
    setVisible(true);
    setProgramId(newProgramId);
  }, []);

  const handleClose = useCallback(() => {
    setProgramId(null);
    setVisible(false);
  }, []);

  const handleSave = useCallback(async () => {
    if (program) {
      setSaving(true);
      await dispatch(assignProgramUsers({ program, newProgramUsers: selectedClients }));
      setSaving(false);
    }

    handleClose();
  }, [dispatch, handleClose, program, selectedClients]);

  // Set the default selected clients
  useEffect(() => {
    setSelectedClients(program?.users || []);
  }, [program]);

  return (
    <ProgramClientContext.Provider
      value={{
        openProgramClientModal: handleOpen,
        closeProgramClientModal: handleClose,
      }}
    >
      {children}
      <Dialog
        keepMounted={false}
        fullScreen
        open={visible}
        onClose={handleSave}
        TransitionComponent={Transition}
        PaperProps={{
          style: {
            backgroundColor:
              theme.palette.mode === 'dark'
                ? theme.palette.grey[700]
                : theme.palette.background.default,
          },
        }}
      >
        <IconButton
          onClick={handleSave}
          sx={{
            zIndex: 9,
            position: 'absolute',
            top: 10,
            right: 10,
            backgroundColor: 'background.paper',
          }}
        >
          <Iconify
            icon={'fe:close'}
            color="text.primary"
            width={30}
            height={30}
            sx={{ opacity: 0.5, ':hover': { opacity: 1 } }}
          />
        </IconButton>

        <Box sx={{ p: 4 }}>
          <Typography variant="body2" color="text.secondary" sx={{ mb: 4, display: 'block' }}>
            Assign clients to {program?.title}.
          </Typography>
          <Autocomplete
            multiple
            options={clients}
            value={selectedClients}
            disableCloseOnSelect
            getOptionLabel={(option) => option.name}
            isOptionEqualToValue={(option, value) => option.id === value?.id}
            onChange={(event, value) => setSelectedClients(value)}
            renderOption={(props, option, { selected }) => (
              <li {...props} key={option.id}>
                <Checkbox style={{ marginRight: 8 }} checked={selected} />
                {option.name}
              </li>
            )}
            renderInput={(params) => (
              <TextField {...params} label="Clients" placeholder="Assign to Client" />
            )}
          />

          <Stack direction="row" spacing={2} sx={{ mt: 4 }}>
            <LoadingButton variant="contained" onClick={handleSave} loading={saving}>
              Save
            </LoadingButton>
            <Button variant="contained" color="error" onClick={handleClose}>
              Cancel
            </Button>
          </Stack>
        </Box>
      </Dialog>
    </ProgramClientContext.Provider>
  );
}

export { ProgramClientProvider, ProgramClientContext };
