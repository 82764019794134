import { doc, writeBatch } from 'firebase/firestore';
import { ProgramWeek_WithID, WorkoutExercise, Workout_WithID } from 'src/@types/firebase';
import { DB } from 'src/contexts/FirebaseContext';

type Props = {
  programWeeks: ProgramWeek_WithID[];
  workouts: Workout_WithID[];
  template?: boolean;
};

type ProgramWeekChanges = {
  [key: string]: ProgramWeek_WithID;
};
type FixedWorkouts = {
  [key: string]: Workout_WithID;
};

const patchBuggedPrograms = async ({ programWeeks, workouts, template }: Props) => {
  const programWeeksWithFixedIssues = [...programWeeks];
  const programWeekChanges: ProgramWeekChanges = {};

  const workoutsWithFixedIssues = [...workouts];
  const workoutChanges: FixedWorkouts = {};

  // Do this in program load
  // Also update program creator image if needed

  // TODO: Add patch for:
  // program week indexing issues,
  // workout indexing issues,
  // workout exercise group indexing issues, workout exercise group wrong workout issues,
  // workout exercise indexing issues, workout exercise wrong workout issues,
  // workout exercise metric indexing issues, workout exercise metric wrong workout issues,
  // workout exercise metric value indexing issues, workout exercise metric value wrong workout issues

  // ----------------------------
  // Checks
  // ----------------------------

  // Find any programWeek issues
  // Check if programWeeks are in order
  // Sort programWeeks by index
  const sortedProgramWeeks = programWeeksWithFixedIssues.sort((a, b) => a.index - b.index);
  // Check if programWeeks are sequential (0 based)
  const programWeeksAreSequential = sortedProgramWeeks.every(
    (programWeek, index) => programWeek.index === index
  );
  // If not sequential, fix and add to programWeeksWithFixedIssues
  if (!programWeeksAreSequential) {
    sortedProgramWeeks.forEach((programWeek, index) => {
      const fix = {
        ...programWeek,
        index,
      };
      programWeekChanges[programWeek.id] = fix;
      // Replace programWeek in programWeeksWithFixedIssues
      programWeeksWithFixedIssues[
        programWeeksWithFixedIssues.findIndex((pw) => pw.id === programWeek.id)
      ] = fix;
    });
  }

  // Find any workout issues
  // Check if workouts are in order
  // Group workouts by programWeekId
  const workoutsGroupedByWeek = workoutsWithFixedIssues.reduce((acc, workout) => {
    const { programWeekId } = workout;
    if (!acc[programWeekId]) {
      acc[programWeekId] = [];
    }
    acc[programWeekId].push(workout);
    return acc;
  }, {} as { [key: string]: Workout_WithID[] });
  // Sort each group of workouts by index
  Object.keys(workoutsGroupedByWeek).forEach((key) => {
    workoutsGroupedByWeek[key].sort((a, b) => a.index - b.index);

    // Check if workouts are sequential (0 based)
    const workoutsAreSequential = workoutsGroupedByWeek[key].every(
      (workout, index) => workout.index === index
    );

    // If not sequential, fix and add to workoutsWithFixedIssues
    if (!workoutsAreSequential) {
      workoutsGroupedByWeek[key].forEach((workout, index) => {
        const fix = {
          ...workout,
          index,
        };
        workoutChanges[workout.id] = fix;
        // Replace workout in workoutsWithFixedIssues
        workoutsWithFixedIssues[workoutsWithFixedIssues.findIndex((w) => w.id === workout.id)] =
          fix;

        if (process.env.NODE_ENV === 'development') {
          // console.log(`%c [BUG FIX]: Workouts in Week ${key} are not sequential`); with background color green
          console.log(
            `%c [BUG FIX]: Workouts in Week ${key} are not sequential`,
            'background: green; color: white; display: block;'
          ); // eslint-disable-line no-console
        }
      });
    }
  });

  // Find workouts with misisng workoutExerciseGroups
  const workoutsThatHaveExercisesWithNoGroups = findWorkoutsThatHaveExercisesWithNoGroups({
    workouts: workoutsWithFixedIssues,
  });
  // Check if there are any results
  if (workoutsThatHaveExercisesWithNoGroups.length) {
    // If so, fix and add to workoutsWithFixedIssues
    const fixedWorkouts = fixWorkouts({
      workouts: workoutsWithFixedIssues,
      workoutsThatHaveExercisesWithNoGroups,
    });

    fixedWorkouts.forEach((workout) => {
      workoutChanges[workout.id] = workout;
      // Replace workout in workoutsWithFixedIssues
      workoutsWithFixedIssues[workoutsWithFixedIssues.findIndex((w) => w.id === workout.id)] =
        workout;

      if (process.env.NODE_ENV === 'development') {
        console.log(
          `%c [BUG FIX]: Workout ${workout.name} ${workout.id} has exercises with no groups`,
          'background: green; color: white; display: block;'
        ); // eslint-disable-line no-console
      }
    });
  }

  // Find Workout Exercise Group issues
  // Check if workoutExerciseGroups are in order
  // Group workoutExerciseGroups by workoutId
  workoutsWithFixedIssues.forEach((workout) => {
    const workoutExerciseGroups = workout?.workoutExerciseGroups;

    if (!workoutExerciseGroups?.length) {
      if (process.env.NODE_ENV === 'development') {
        console.log(`No workoutExerciseGroups for workout ${workout.id}`);
      }
      return;
    }

    // Sort workoutExerciseGroups by index
    const sortedWorkoutExerciseGroups = workoutExerciseGroups.sort((a, b) => a.index - b.index);

    // Check if workoutExerciseGroups are sequential (0 based)
    const workoutExerciseGroupsAreSequential = sortedWorkoutExerciseGroups.every(
      (workoutExerciseGroup, index) => workoutExerciseGroup.index === index
    );

    // If not sequential, fix and add to workoutsWithFixedIssues
    if (!workoutExerciseGroupsAreSequential) {
      const fixedWorkoutExerciseGroups = sortedWorkoutExerciseGroups.map(
        (workoutExerciseGroup, index) => ({
          ...workoutExerciseGroup,
          index,
        })
      );

      const fix = {
        ...workout,
        workoutExerciseGroups: fixedWorkoutExerciseGroups,
      };

      workoutChanges[workout.id] = fix;
      // Replace workout in workoutsWithFixedIssues
      workoutsWithFixedIssues[workoutsWithFixedIssues.findIndex((w) => w.id === workout.id)] = fix;

      if (process.env.NODE_ENV === 'development') {
        console.log(
          `%c [BUG FIX]: Workout ${workout.id} has workoutExerciseGroups that are not sequential`,
          'background: green; color: white; display: block;'
        ); // eslint-disable-line no-console
      }
    }
  });

  // Find Workout Exercise issues
  const workoutExercieChanges: {
    [key: string]: WorkoutExercise;
  } = {};
  // Check if workoutExercises are in order
  // Group workoutExercises by workoutId
  workoutsWithFixedIssues.forEach((workout) => {
    const workoutExercises = workout?.workoutExercises;

    if (!workoutExercises?.length) {
      if (process.env.NODE_ENV === 'development') {
        console.log(`No workoutExercises for ${workout.id}`);
      }
      return;
    }

    // Group workoutExercises by workoutExerciseGroupId
    const workoutExercisesGroupedByGroupId = workoutExercises?.reduce((acc, workoutExercise) => {
      const { workoutExerciseGroupId } = workoutExercise;
      if (!acc[workoutExerciseGroupId]) {
        acc[workoutExerciseGroupId] = [];
      }
      acc[workoutExerciseGroupId].push(workoutExercise);
      return acc;
    }, {} as { [key: string]: WorkoutExercise[] });

    Object.keys(workoutExercisesGroupedByGroupId).forEach((key) => {
      const groupWorkoutExercises = workoutExercisesGroupedByGroupId[key];

      // Sort workoutExercises by index
      const sortedWorkoutExercises = groupWorkoutExercises.sort((a, b) => a.index - b.index);

      // Check if workoutExercises are sequential (0 based)
      const workoutExercisesAreSequential = sortedWorkoutExercises.every(
        (workoutExercise, index) => workoutExercise.index === index
      );

      // If not sequential, fix and add to workoutsWithFixedIssues
      if (!workoutExercisesAreSequential) {
        const fixedWorkoutExercises = sortedWorkoutExercises.map((workoutExercise, index) => ({
          ...workoutExercise,
          index,
        }));

        fixedWorkoutExercises.forEach((workoutExercise) => {
          workoutExercieChanges[workoutExercise.id] = workoutExercise;
        });
      }
    });

    if (Object.keys(workoutExercieChanges).length) {
      const fixedWorkoutExercises = workoutExercises.map((workoutExercise) => {
        if (workoutExercieChanges[workoutExercise.id]) {
          return workoutExercieChanges[workoutExercise.id];
        }
        return workoutExercise;
      });

      const fix = {
        ...workout,
        workoutExercises: fixedWorkoutExercises,
      };

      workoutChanges[workout.id] = fix;
      // Replace workout in workoutsWithFixedIssues
      workoutsWithFixedIssues[workoutsWithFixedIssues.findIndex((w) => w.id === workout.id)] = fix;

      if (process.env.NODE_ENV === 'development') {
        console.log(
          `%c [BUG FIX]: Workout ${workout.id} has workoutExercises that are not sequential`,
          'background: green; color: white; display: block;'
        ); // eslint-disable-line no-console
      }
    }
  });

  // Log the changes
  if (process.env.NODE_ENV === 'development') {
    if (Object.keys(programWeekChanges).length || Object.keys(workoutChanges).length) {
      console.log('Completed patching bugged program');
      console.log('programWeekChanges', programWeekChanges);
      console.log('workoutChanges', workoutChanges);
    }
  }

  // Update to firebase
  if (Object.keys(programWeekChanges).length || Object.keys(workoutChanges).length) {
    const batch = writeBatch(DB);

    if (Object.keys(programWeekChanges).length) {
      Object.keys(programWeekChanges).forEach((programWeekId) => {
        const { id, ...data } = programWeekChanges[programWeekId];
        const programWeekRef = doc(
          DB,
          template ? 'programTemplates' : 'programs',
          data.programId,
          'programWeeks',
          id
        );
        batch.update(programWeekRef, { ...data });
      });
    }

    if (Object.keys(workoutChanges).length) {
      Object.keys(workoutChanges).forEach((workoutId) => {
        const { id, ...data } = workoutChanges[workoutId];
        const workoutRef = doc(
          DB,
          template ? 'programTemplates' : 'programs',
          data.programId,
          'programWeeks',
          data.programWeekId,
          'workouts',
          id
        );
        batch.update(workoutRef, { ...data });
      });
    }

    await batch.commit();

    if (process.env.NODE_ENV === 'development') {
      console.log(
        `%c Firebase: Updated ${Object.keys(programWeekChanges).length} programWeeks and ${
          Object.keys(workoutChanges).length
        } workouts`,
        'background: green; color: white; display: block;'
      ); // eslint-disable-line no-console
    }
  }

  // Return updated fetchData
  return {
    workouts: workoutsWithFixedIssues,
    programWeeks: programWeeksWithFixedIssues,
  };
};

export default patchBuggedPrograms;

// --------------------------------------------------
// Helpers
// --------------------------------------------------

// --------------------------------------------------

const findWorkoutsThatHaveExercisesWithNoGroups = ({
  workouts,
}: {
  workouts: Workout_WithID[];
}) => {
  const workoutsThatHaveExercisesWithNoGroups: {
    [key: string]: Workout_WithID;
  } = {};

  workouts.forEach((workout) => {
    // For each workout exercise
    const workoutExercises = workout?.workoutExercises;

    if (!workoutExercises?.length) {
      if (process.env.NODE_ENV === 'development') {
        console.log(`No workoutExercises for workout ${workout.id}`);
      }
      return;
    }

    workoutExercises.forEach((workoutExercise) => {
      const workoutExerciseGroupId = workoutExercise?.workoutExerciseGroupId;

      if (!workoutExerciseGroupId) {
        if (process.env.NODE_ENV === 'development') {
          console.log(`No workoutExerciseGroupId for workoutExercise ${workoutExercise.id}`);
        }
        return;
      }

      const workoutExerciseGroup = workout?.workoutExerciseGroups?.find(
        (group) => group.id === workoutExerciseGroupId
      );

      if (!workoutExerciseGroup) {
        workoutsThatHaveExercisesWithNoGroups[workout.id] = workout;
      }
    });
  });

  return Object.values(workoutsThatHaveExercisesWithNoGroups);
};

// --------------------------------------------------

type FixWorkoutsProps = {
  workouts: Workout_WithID[];
  workoutsThatHaveExercisesWithNoGroups: Workout_WithID[];
};

const fixWorkouts = ({ workouts, workoutsThatHaveExercisesWithNoGroups }: FixWorkoutsProps) => {
  const fixedWorkouts: {
    [key: string]: Workout_WithID;
  } = {};

  workoutsThatHaveExercisesWithNoGroups.forEach((workout) => {
    // For each workout exercise
    const allWorkoutExercises = workout?.workoutExercises;

    if (!allWorkoutExercises?.length) {
      if (process.env.NODE_ENV === 'development') {
        console.log(`No workoutExercises for workout ${workout.id}`);
      }
      return;
    }

    allWorkoutExercises.forEach((workoutExercise) => {
      const workoutExerciseGroupId = workoutExercise?.workoutExerciseGroupId;

      if (!workoutExerciseGroupId) {
        if (process.env.NODE_ENV === 'development') {
          console.log(`No workoutExerciseGroupId for exercise ${workoutExercise.id}`);
        }
        return;
      }

      // Find the workout that has the workoutExerciseGroupId
      const workoutWithGroup = workouts.find((w) =>
        w?.workoutExerciseGroups?.find(
          (group) => w.id !== workout.id && group.id === workoutExerciseGroupId
        )
      );

      if (!workoutWithGroup) {
        if (process.env.NODE_ENV === 'development') {
          console.log(`No workout with group for exercise ${workoutExercise.id}`);
        }
        return;
      }

      // Get the workoutExerciseMetrics, and workoutExerciseMetricValues for this exercise
      const workoutNewExerciseMetrics = workout.workoutExerciseMetrics.filter(
        (metric) => metric.workoutExerciseId === workoutExercise.id
      );
      const workoutNewExerciseMetricValues = workout.workoutExerciseMetricValues.filter(
        (metricValue) => metricValue.workoutExerciseId === workoutExercise.id
      );
      const workoutNewExercises = [workoutExercise];

      // Fix the workout with the group to have the right details
      const workoutExists = fixedWorkouts[workoutWithGroup.id];
      if (workoutExists) {
        const workoutExerciseMetrics = [
          ...workoutExists.workoutExerciseMetrics,
          ...workoutNewExerciseMetrics,
        ];
        const workoutExerciseMetricValues = [
          ...workoutExists.workoutExerciseMetricValues,
          ...workoutNewExerciseMetricValues,
        ];
        const workoutExercises = [...workoutExists.workoutExercises, ...workoutNewExercises];

        workoutExists.workoutExerciseMetrics = workoutExerciseMetrics;
        workoutExists.workoutExerciseMetricValues = workoutExerciseMetricValues;
        workoutExists.workoutExercises = workoutExercises;

        workoutExists.exerciseIds = workoutExists.workoutExercises.map(
          (exercise) => exercise.exerciseId
        );
        workoutExists.exerciseMetricIds = workoutExists.workoutExerciseMetrics.map(
          (metric) => metric.exerciseMetricId
        );
      } else {
        const workoutExerciseMetrics = [
          ...workoutWithGroup.workoutExerciseMetrics,
          ...workoutNewExerciseMetrics,
        ];
        const workoutExerciseMetricValues = [
          ...workoutWithGroup.workoutExerciseMetricValues,
          ...workoutNewExerciseMetricValues,
        ];
        const workoutExercises = [...workoutWithGroup.workoutExercises, ...workoutNewExercises];

        fixedWorkouts[workoutWithGroup.id] = {
          ...workoutWithGroup,
          workoutExerciseMetrics: workoutExerciseMetrics,
          workoutExerciseMetricValues: workoutExerciseMetricValues,
          workoutExercises: workoutExercises,
          exerciseIds: workoutExercises.map((exercise) => exercise.exerciseId),
          exerciseMetricIds: workoutExerciseMetrics.map((metric) => metric.exerciseMetricId),
        };
      }

      // Fix the workout with the exercise to have the right details
      // Remove the exercise and other details from the workout
      const workout2Exists = fixedWorkouts[workout.id];
      if (workout2Exists) {
        const workoutExerciseMetrics = workout2Exists.workoutExerciseMetrics.filter(
          (metric) => metric.workoutExerciseId !== workoutExercise.id
        );
        const workoutExerciseMetricValues = workout2Exists.workoutExerciseMetricValues.filter(
          (metricValue) => metricValue.workoutExerciseId !== workoutExercise.id
        );
        const workoutExercises = workout2Exists.workoutExercises.filter(
          (exercise) => exercise.id !== workoutExercise.id
        );

        workout2Exists.workoutExerciseMetrics = workoutExerciseMetrics;
        workout2Exists.workoutExerciseMetricValues = workoutExerciseMetricValues;
        workout2Exists.workoutExercises = workoutExercises;

        workout2Exists.exerciseIds = workout2Exists.workoutExercises.map(
          (exercise) => exercise.exerciseId
        );
        workout2Exists.exerciseMetricIds = workout2Exists.workoutExerciseMetrics.map(
          (metric) => metric.exerciseMetricId
        );
      } else {
        const workoutExerciseMetrics = workout.workoutExerciseMetrics.filter(
          (metric) => metric.workoutExerciseId !== workoutExercise.id
        );
        const workoutExerciseMetricValues = workout.workoutExerciseMetricValues.filter(
          (metricValue) => metricValue.workoutExerciseId !== workoutExercise.id
        );
        const workoutExercises = workout.workoutExercises.filter(
          (exercise) => exercise.id !== workoutExercise.id
        );

        fixedWorkouts[workout.id] = {
          ...workout,
          workoutExerciseMetrics: workoutExerciseMetrics,
          workoutExerciseMetricValues: workoutExerciseMetricValues,
          workoutExercises: workoutExercises,
          exerciseIds: workoutExercises.map((exercise) => exercise.exerciseId),
          exerciseMetricIds: workoutExerciseMetrics.map((metric) => metric.exerciseMetricId),
        };
      }
    });
  });

  return Object.values(fixedWorkouts);
};
