import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'src/redux/store';
import { getUserId } from 'src/redux/slices/user';
import {
  getClientsFetchError,
  getClientsFetchStatus,
  selectAllCoachClients,
  selectActiveClientsList,
  selectActiveClientsListFirstAndLast,
  startClientsListener,
  updateClient,
} from 'src/redux/slices/clients';
import { Alert } from '@mui/material';
import {
  CLIENT_ACTIVE_LIST_STATUS_TYPES,
  CLIENT_STATUS_ENUM,
  FETCH_STATUS_TYPES_ENUM,
} from 'src/@types/enums';
// import useCurrentUser from './useCurrentUser';
import { useSnackbar } from 'notistack';
// import useStripeModal from './useStripeModal';
// import { STRIPE_MODAL_TYPES } from 'src/contexts/StripeModalContext';
import { doc, updateDoc } from 'firebase/firestore';
import { DB } from 'src/contexts/FirebaseContext';
import { CoachClient } from 'src/@types/firebase';

// ----------------------------------------------------------------------

export const MAX_FREE_CLIENTS = 3;

export default function useClients() {
  const dispatch = useDispatch();
  const coachId = useSelector(getUserId);
  const clients = useSelector(selectAllCoachClients);
  const clientsList = useSelector(selectActiveClientsList);
  const clientsListFirstAndLast = useSelector(selectActiveClientsListFirstAndLast);
  const fetchStatus = useSelector(getClientsFetchStatus);
  const fetchError = useSelector(getClientsFetchError);

  const loading =
    fetchStatus === FETCH_STATUS_TYPES_ENUM.LOADING || fetchStatus === FETCH_STATUS_TYPES_ENUM.IDLE;

  // const { clientLimitReached, isSubscribed, hasUpgradedClientSubscription } = useCurrentUser();
  const { enqueueSnackbar } = useSnackbar();
  // const { openStripeModal, closeStripeModal } = useStripeModal();

  useEffect(() => {
    if (coachId && fetchStatus === FETCH_STATUS_TYPES_ENUM.IDLE) {
      dispatch(startClientsListener(coachId));
    }
  }, [fetchStatus, coachId, dispatch]);

  const ErrorAlert = () => (!!fetchError ? <Alert severity="error">{fetchError}</Alert> : null);

  const handleClientStatusChange = async ({
    coachClientId,
    newStatus,
  }: {
    coachClientId: string;
    newStatus: CLIENT_STATUS_ENUM;
  }) => {
    await dispatch(
      updateClient({
        coachClientId,
        updates: { status: newStatus },
      })
    );
    enqueueSnackbar('Client marked as ' + newStatus.toLowerCase(), { variant: 'success' });
  };

  const acceptRequest = async ({
    coachClientId,
    clientName,
    status,
  }: {
    coachClientId: string;
    clientName: string;
    status: CLIENT_STATUS_ENUM;
  }) => {
    await updateClientStatus(coachClientId, clientName, status);
    // Set coachClient request.pending to false
    const coachClientRef = doc(DB, 'coachClients', coachClientId);
    const updates: Partial<CoachClient> = {
      request: {
        date: new Date(),
        pending: false,
      },
    };

    await updateDoc(coachClientRef, updates);
  };

  const rejectRequest = async ({ coachClientId }: { coachClientId: string }) => {
    // Set coachClient request.pending to false
    const coachClientRef = doc(DB, 'coachClients', coachClientId);
    const updates: Partial<CoachClient> = {
      request: {
        date: new Date(),
        pending: false,
      },
    };

    await updateDoc(coachClientRef, updates);
  };

  const updateClientStatus = async (
    coachClientId: string,
    clientName: string,
    status: CLIENT_STATUS_ENUM
  ) => {
    const inActiveList = CLIENT_ACTIVE_LIST_STATUS_TYPES.includes(status);

    let newStatus = CLIENT_STATUS_ENUM.ACTIVE;

    // Changing from active to inactive
    if (inActiveList) {
      // if (isSubscribed) {
      //   newStatus = CLIENT_STATUS_ENUM.EXPIRING;
      // } else {
      newStatus = CLIENT_STATUS_ENUM.INACTIVE;
      // }
    } else if (status === CLIENT_STATUS_ENUM.EXPIRING) {
      // Changing from expiring to active
    } else {
      // if (clientLimitReached) {
      //   // The type of modal depends on whether the user is subscribed or not
      //   openStripeModal({
      //     type: hasUpgradedClientSubscription
      //       ? STRIPE_MODAL_TYPES.ADD_NEW_CLIENT
      //       : STRIPE_MODAL_TYPES.ADD_PAYMENT_METHOD,
      //     name: clientName,
      //     onConfirm: async () => {
      //       await handleClientStatusChange({ coachClientId, newStatus });
      //       closeStripeModal();
      //     },
      //   });
      //   // Prevent from moving forward
      //   return;
      // }
    }

    handleClientStatusChange({ coachClientId, newStatus });
  };

  return {
    loading,
    clients,
    clientsList,
    clientsListFirstAndLast,
    fetchStatus,
    fetchError,
    updateClientStatus,
    acceptRequest,
    rejectRequest,
    ErrorAlert,
  };
}
