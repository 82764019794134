import { createAction, createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { User_WithID } from 'src/@types/firebase';
import { AppDispatch, RootState } from 'src/redux/store';
import { FETCH_STATUS_TYPES_ENUM, USER_TYPE_ENUM } from 'src/@types/enums';
import { doc, onSnapshot, Unsubscribe, updateDoc } from 'firebase/firestore';
import { ANALYTICS, DB } from 'src/contexts/FirebaseContext';
import { CustomFile } from 'src/components/upload';
import handleSaveUserImage from '../functions/handleSaveUserImage';
import { setUserId, setUserProperties } from 'firebase/analytics';
import convertFirebaseDataDates from 'src/utils/convertFirebaseDataDates';
import addSelfAsClient from 'src/utils/addSelfAsClient';
import makeUserACoach from 'src/utils/makeUserACoach';
import setUserLastActiveDate from 'src/utils/setUserLastActiveDate';

const initialState: User_WithID & {
  authenticated: boolean;
  initialized: boolean;
  status: FETCH_STATUS_TYPES_ENUM;
  error: string | null;
  listener: null | Unsubscribe;
} = {
  dateCreated: new Date(),
  id: '',
  email: '',
  phone: '',
  firstName: '',
  lastName: '',
  profilePictureUrl: '',
  authenticated: false,
  initialized: false,
  status: FETCH_STATUS_TYPES_ENUM.IDLE,
  error: null,
  listener: null,
};

// Save program image
export const saveUserImage = createAsyncThunk<
  string,
  {
    userId: string;
    profilePictureUrl?: string;
    file?: CustomFile;
  }
>('user/saveUserImage', async ({ userId, profilePictureUrl, file }) => {
  const newImageUrl = await handleSaveUserImage({
    userId,
    profilePictureUrl,
    file,
  });

  if (!newImageUrl) {
    throw new Error('Error saving user image, no image url returned');
  }

  return newImageUrl;
});

// Save user
export const saveUser = createAsyncThunk<
  Partial<User_WithID>,
  Partial<User_WithID> & { id: string }
>('user/saveUser', async ({ id, ...user }) => {
  // Save user to firebase
  const ref = doc(DB, 'users', id);

  await updateDoc(ref, {
    ...user,
    lastUpdated: new Date(),
  });
  return user;
});

export const logoutAction = createAction('userAccount/logout');

export const slice = createSlice({
  name: 'userAccount',
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<User_WithID>) => ({
      ...action.payload,
      listener: state.listener,
      error: null,
      status: FETCH_STATUS_TYPES_ENUM.SUCCEEDED,
      initialized: true,
      authenticated: true,
    }),
    // --------------------------------------------------
    // Listener commands start
    // --------------------------------------------------
    fetchConnectionsRequestsLoading(state) {
      state.status = FETCH_STATUS_TYPES_ENUM.LOADING;
    },
    fetchConnectionsRequestsSuccess(state) {
      state.status = FETCH_STATUS_TYPES_ENUM.SUCCEEDED;
      state.initialized = true;
      state.authenticated = true;
    },
    fetchConnectionsRequestsFailure(state, action) {
      state.status = FETCH_STATUS_TYPES_ENUM.FAILED;
      state.error = action.payload;
      state.initialized = true;
      state.authenticated = false;
      console.error(action.payload);
    },
    setListener: (state, action) => {
      state.listener = action.payload;
    },
    stopListener: (state) => {
      if (state.listener) {
        state.listener();
      }
      state.listener = null;
      state.initialized = false;
      state.authenticated = false;
    },
    // --------------------------------------------------
    reset: (state) => {
      if (state.listener) {
        state.listener();
      }
      return initialState;
    },
  },
  extraReducers(builder) {
    const handleReset = (state: any) => {
      if (state.listener) {
        state.listener();
      }
      return initialState;
    };

    builder
      // Reset
      .addCase(logoutAction, handleReset);
  },
});

export const {
  setUser,
  fetchConnectionsRequestsLoading,
  fetchConnectionsRequestsSuccess,
  fetchConnectionsRequestsFailure,
  setListener,
  stopListener,
  reset,
} = slice.actions;

export default slice.reducer;

// ----------------------------------------------------------------------
// Thunks
// ----------------------------------------------------------------------
export const startUserListener =
  (userId: string) => async (dispatch: AppDispatch, getState: () => RootState) => {
    const state = getState();
    const userListener = state.user.listener;

    if (userListener) {
      console.error('User listener already exists');
      return;
    }

    let hasSetLastActive = false; // Local flag to ensure single execution

    dispatch(fetchConnectionsRequestsLoading());
    try {
      const unsubscribe = await onSnapshot(doc(DB, 'users', userId), (doc) => {
        const { id } = doc;
        const data = doc.data();

        // If no data firstName, lastName, email, or dateCreated then the user has been registered but not completed the onboarding process
        const userNotFullyRegistered =
          !data?.firstName || !data?.lastName || !data?.email || !data?.dateCreated;
        if (userNotFullyRegistered) {
          if (process.env.NODE_ENV === 'development') {
            console.error('User not fully registered');
          }
          return;
        }

        convertFirebaseDataDates(data);

        const item = { ...data, id } as User_WithID;

        // Run this only once
        if (!hasSetLastActive) {
          const previousLastActiveDate = item.lastActiveDate;
          setUserLastActiveDate(userId, previousLastActiveDate); // This runs only once
          hasSetLastActive = true; // Set the flag to true after running
        }

        // Set analytics data
        // Set user id
        setUserId(ANALYTICS, userId);

        // Set user properties
        // Set if users is a coach
        if (item.type?.includes(USER_TYPE_ENUM.COACH)) {
          setUserProperties(ANALYTICS, {
            isCoach: true,
          });
        } else {
          // Make them a coach
          makeUserACoach(item);
          // Add the coach as a client to themselves
          addSelfAsClient(item);
        }

        dispatch(setUser(item));
      });
      dispatch(setListener(unsubscribe));
    } catch (error) {
      dispatch(fetchConnectionsRequestsFailure(error));
    }
  };

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------
// SELECTORS
// ----------------------------------------------------------------------

export const getUser = (state: RootState) => state.user;
export const getUserType = (state: RootState) => state.user.type;
export const getUserCustomMobileAppEnabled = (state: RootState) =>
  state.user.customMobileApp?.enabled;
export const getUserStatus = (state: RootState) => state.user.status;
export const getUserId = (state: RootState) => state.user.id;
export const getUserAuth = (state: RootState) => ({
  authenticated: state.user.authenticated,
  initialized: state.user.initialized,
});
// ----------------------------------------------------------------------
